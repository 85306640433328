import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
    namespaced: true,
    state() {
        return {
            token: localStorage.getItem("Manfaa_App_Token"),
            usertype: localStorage.getItem("Manfaa_App_User_Type"),
            user_id: localStorage.getItem("Manfaa_App_User_Id"),
            userImage: localStorage.getItem("Manfaa_App_userImg"),
            userPhone: localStorage.getItem("Manfaa_App_userPhone"),
            devMessage: localStorage.getItem("Manfaa_App_devMessage"),
        };
    },
    mutations,
    actions,
    getters,
};