<template>
  <div class="static_pages pad_70 all_bg faqs">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="main_title with_margin">
            <h3>{{ $t("static.faqs") }}</h3>
          </div>
          <div class="all_text" v-for="faq in faqs" :key="faq.id">
            <h4>
              {{ faq.question }}
            </h4>
            <p>
              {{ faq.answer }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqs: null,
    };
  },
  methods: {
    getData() {
      this.$axios({
        method: "GET",
        url: "faqs?type=client",
      }).then((res) => {
        this.faqs = res.data.data;
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
