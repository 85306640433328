<template>
  <data-loader v-if="loading"></data-loader>
  <div class="user_profile pad_70 all_bg" v-else>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <!--User Info-->
          <div class="user_info">
            <img @error="setDefultImage" :src="myData.image" />
            <h4>{{ myData.first_name }} {{ myData.last_name }}</h4>
            <Rate :rate_num="Math.floor(myData.rates)"></Rate>
          </div>
          <!--User Info-->
          <myTabs :tabsContent="tabsContent">
            <template v-slot:myData>
              <div class="row justify-content-center">
                <div class="col-md-8">
                  <!-- <MyData :myData="myData"></MyData> -->
                  <div class="row">
                    <div
                      class="col-md-4"
                      v-for="product in products"
                      :key="product.id"
                    >
                      <ProductCard :productItem="product"></ProductCard>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:rating>
              <div class="row">
                <div class="col-md-12">
                  <no-data v-if="reviews.length == 0"></no-data>
                  <RatingBox :rating="reviews" v-else></RatingBox>
                </div>
                <div
                  class="col-md-12"
                  v-if="$store.getters['auth_module/token']"
                >
                  <h4 class="rate_tit">{{ $t("rate.addRate") }}</h4>
                  <RatingForm :providerId="id"></RatingForm>
                </div>
              </div>
            </template>
          </myTabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyData from "./Data/MyData.vue";
import RatingBox from "./Rating/Rating.vue";
import RatingForm from "./Rating/RatingForm.vue";
import ProductCard from "@/components/HomePage/Products/ProductCard.vue";
export default {
  props: ["id"],
  data() {
    return {
      // START:: TABS DATA
      tabsContent: [
        {
          tab: this.$t("profile.myAds.title"),
          key_name: "myData",
        },
        {
          tab: this.$t("profile.rating"),
          key_name: "rating",
        },
      ],
      // END:: TABS DATA

      // START:: MY DATA
      myData: null,
      // End:: MY DATA

      // START:: Reviews
      reviews: null,
      // End:: Reviews

      // START:: Loading
      loading: false,
      // END:: Loading

      // START:: Products
      products: [],
      // END:: Products
    };
  },
  methods: {
    // START:: Get My Data
    getData() {
      this.loading = true;
      this.$axios
        .get("/service-provider?provider_id=" + this.id)
        .then((res) => {
          this.loading = false;
          this.myData = res.data.data.profile;
          this.reviews = res.data.data.reviews;
          this.products = res.data.data.products;
        });
    },
    // END:: Get My Data
    //Replace Image
    setDefultImage(e) {
      e.target.src = require("@/assets/images/Icons/hidden-image.png");
    },
    //Replace Image
  },
  components: {
    MyData,
    RatingBox,
    RatingForm,
    ProductCard,
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
