export default {
    // ============================= Login
    set_token(state, payload) {
        localStorage.setItem("Manfaa_App_Token", payload);
        state.token = payload;
    },
    set_usertype(state, payload) {
        localStorage.setItem("Manfaa_App_User_Type", payload);
        state.usertype = payload;
    },
    set_userId(state, payload) {
        localStorage.setItem("Manfaa_App_User_Id", payload);
        state.user_id = payload;
    },
    set_userImage(state, payload) {
        localStorage.setItem("Manfaa_App_userImg", payload);
        state.userImage = payload;
    },
    set_userPhone(state, payload) {
        localStorage.setItem("Manfaa_App_userPhone", payload);
        state.userPhone = payload;
    },
    set_devMessage(state, payload) {
        localStorage.setItem("Manfaa_App_devMessage", payload);
        state.devMessage = payload;
    },

    // ============================= Logout
    remove_token(state) {
        state.token = "";
        localStorage.removeItem("Manfaa_App_Token");
    },
    remove_userType(state) {
        state.usertype = "";
        localStorage.removeItem("Manfaa_App_User_Type");
    },
    remove_userId(state) {
        state.user_id = "";
        localStorage.removeItem("Manfaa_App_User_Id");
    },
    remove_userImage(state) {
        state.userImage = "";
        localStorage.removeItem("Manfaa_App_userImg");
    },
    remove_userPhone(state) {
        state.userPhone = "";
        localStorage.removeItem("Manfaa_App_userPhone");
    },
};