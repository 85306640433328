<template>
  <router-link :to="'/orderDetails/' + order.id">
    <div class="order_card">
      <div class="order_right">
        <div class="image">
          <img
            @error="setDefultImage"
            :src="order.product.image ? order.product.image.link : ''"
          />
        </div>
        <div class="exp">
          <h4>
            {{ $t("profile.myOrders.orderNum") + order.id }}
          </h4>
          <span>{{ order.product.name }}</span>
          <span>{{ order.product.category.name }}</span>
        </div>
      </div>
      <div class="order_left">
        <div class="date">
          <span>{{ order.created_at || "2/2/2222" }}</span>
        </div>
        <div class="order_status">
          <span v-if="order.status == 'pending'">{{
            $t("orderStatus.pending")
          }}</span>
          <span v-if="order.status == 'waiting'">{{
            $t("orderStatus.waiting")
          }}</span>
          <span v-if="order.status == 'on_way'">{{
            $t("orderStatus.on_way")
          }}</span>
          <span v-if="order.status == 'arrive'">{{
            $t("orderStatus.arrive")
          }}</span>
          <span v-if="order.status == 'finished'">{{
            $t("orderStatus.finished")
          }}</span>
          <span v-if="order.status == 'cancelled'">{{
            $t("orderStatus.cancelled")
          }}</span>
          <span v-if="order.status == 'completed'">{{
            $t("orderStatus.completed")
          }}</span>
          <span v-if="order.status == 'refused'">{{
            $t("orderStatus.refused")
          }}</span>
          <span v-if="order.status == 'accepted'">{{
            $t("orderStatus.accepted")
          }}</span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["order"],

  data() {
    return {};
  },
  methods: {
    //Replace Image
    setDefultImage(e) {
      e.target.src = require("@/assets/images/Icons/hidden-image.png");
    },
    //Replace Image
  },
  computed: {
    imageLink() {
      return order.product.image?.link;
    },
  },
};
</script>

<style></style>
