import axios from "axios";

export default {

    get_notification(context) {
        let notification = [];
        axios
            .get("/notifications")
            .then((res) => {
                notification = res.data.data;
                context.commit("set_notification", notification);
            });
    },
};