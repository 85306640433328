<template>
  <div class="all_conversation pad_70 all_bg">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="main_title with_margin">
            <h3>{{ $t("chats.title") }}</h3>
          </div>
        </div>
        <data-loader v-if="loading"></data-loader>
        <div class="div" v-else>
          <no-data v-if="chats.length == 0"></no-data>
          <template v-else>
            <div v-for="chat in chats" :key="chat.id" class="col-sm-12">
              <!--Conversation Card-->
              <div class="coversation_card custom_card">
                <router-link :to="`/singleChat/${chat.id}`">
                  <div class="conver_user">
                    <!--Image-->
                    <div class="image">
                      <img @error="setDefultImage" :src="chat.provider.image" />
                    </div>
                    <!--details-->
                    <div class="details">
                      <div class="top_det">
                        <h4>
                          {{
                            chat.provider.first_name + chat.provider.last_name
                          }}
                        </h4>
                        <!-- <Rate :rate_num="chat.provider.rates"></Rate> -->
                      </div>
                      <div class="bot_det">
                        <p>{{ chat.last_message }}</p>
                      </div>
                    </div>
                  </div>
                </router-link>
                <div class="conver_del">
                  <div class="date">
                    <span>{{ chat.created_at }}</span>
                  </div>
                  <div class="icon">
                    <span class="un_read" v-if="chat.has_unreaded_message">{{
                      chat.un_read_messages
                    }}</span>
                    <button
                      class="btn"
                      type="button"
                      @click="showModel(chat.id)"
                    >
                      <i class="fal fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!--Start Delete Chat-->
    <base-model :show="deleteModel" @closeModel="closeModel">
      <div class="delete_caht">
        <div class="icon">
          <i class="fal fa-info"></i>
        </div>
        <div class="exp">
          <p>{{ $t("sureDeleteMes") }}</p>
        </div>
        <div class="bottom_btns">
          <button
            class="main_button button"
            type="button"
            ref="delete"
            @click="deleteChat"
          >
            <i class="fas fa-spinner"></i>
            {{ $t("delete") }}
          </button>
          <button class="second_button" type="button" @click="closeModel">
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
    </base-model>
    <!--End Delete Chat-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      //Start Chats
      chats: [],
      //End Chats
      //Start Loading
      loading: false,
      //End Loading

      //Start Delete
      deleteModel: false,
      deleteId: null,
      //End Delete
    };
  },
  methods: {
    //Get Chats
    getChats() {
      this.loading = true;
      this.$axios.get(`client/all/chats`).then((res) => {
        this.loading = false;
        this.chats = res.data.data;
      });
    },
    //Get Chats
    //Delete Chat
    deleteChat() {
      const submitButton = this.$refs["delete"];
      submitButton.classList.add("click");
      const data = new FormData();
      data.append("id", this.deleteId);
      this.$axios
        .post("client/chat/delete", data)
        .then(() => {
          setTimeout(() => {
            submitButton.classList.add("click");
            this.closeModel();
            this.getChats();
            this.$iziToast.success({
              timeout: 2000,
              message: this.$t("deleteSuccess"),
              position: "bottomRight",
            });
          }, 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          }, 1000);
        });
    },
    //Show Model
    showModel(id) {
      this.deleteModel = true;
      this.deleteId = id;
    },
    //Show Model

    //Hide Model
    closeModel() {
      this.deleteModel = false;
    },
    //Hide Model
    //Replace Image
    setDefultImage(e) {
      e.target.src = require("@/assets/images/Icons/hidden-image.png");
    },
    //Replace Image
  },

  created() {
    this.getChats();
  },
};
</script>
