<template>
  <div class="edit_data pad_50 all_bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="main_title with_margin">
          <h3>{{ $t("profile.editData.title2") }}</h3>
        </div>
        <div class="col-md-6">
          <!--Start Inputs Part-->
          <div class="auth_inputs mb-3">
            <form>
              <!--Start Current Password-->
              <div class="input_wrapper">
                <input
                  class="form-control"
                  type="password"
                  v-model="data.currentPassword"
                  :placeholder="$t('profile.editData.currentPassword')"
                />
              </div>
              <!--End Current Password-->
              <!--Start New Password-->
              <div class="input_wrapper">
                <input
                  class="form-control"
                  type="password"
                  v-model="data.newPassword"
                  :placeholder="$t('profile.editData.newPassword')"
                />
              </div>
              <!--End New Password-->
              <!--Start Confirm New Password-->
              <!-- <div class="input_wrapper">
                <input
                  class="form-control"
                  type="password"
                  v-model="data.confirmNewPassword"
                  :placeholder="$t('profile.editData.confirmNewPassword')"
                />
              </div> -->
              <!--End Confirm New Password-->
              <!--Edit Button-->
              <button
                type="button"
                class="main_button button mt-3"
                ref="edit"
                @click="editData"
              >
                <i class="fas fa-spinner"></i>
                {{ $t("edit") }}
              </button>
              <!--Edit Button-->
            </form>
          </div>
          <!--End Inputs Part-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // START:: EDIT DATA
      data: {
        currentPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      },
      // END:: EDIT DATA
    };
  },
  methods: {
    // Start Edit Password
    editData() {
      const submitButton = this.$refs["edit"];
      submitButton.classList.add("click");
      if (this.data.currentPassword == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("profile.editData.validation.oldPassword"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.newPassword == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("profile.editData.validation.newPassword"),
            position: "bottomRight",
          });
        }, 1000);
      }
      // else if (this.data.confirmNewPassword == null) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("profile.editData.validation.confirmNewPassword"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      else {
        const data = new FormData();
        data.append("old_password", this.data.currentPassword);
        data.append("password", this.data.newPassword);
        this.$axios
          .post("/edit_password", data)
          .then(() => {
            setTimeout(() => {
              this.$router.push({ path: "/profile" });
              submitButton.classList.remove("click");
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("editSuccess"),
                position: "bottomRight",
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
    //End Edit Password
  },
};
</script>

<style></style>
