<template>
  <div class="login most_bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <!--Start Auth Page-->
          <div class="auth_form auth_center">
            <!--Start Top Part-->
            <div class="auth_top">
              <div class="logo">
                <router-link to="/"
                  ><img src="@/assets/images/Icons/logo.png"
                /></router-link>
              </div>
              <div class="title_content">
                <h4>{{ $t("resetPassword.title") }}</h4>
                <p>{{ $t("resetPassword.desc") }}</p>
              </div>
            </div>
            <!--End Top Part-->
            <!--Start Inputs Part-->
            <div class="auth_inputs">
              <form @submit.prevent="reset">
                <!--Start Password-->
                <div class="input_wrapper">
                  <input
                    class="form-control"
                    type="password"
                    v-model="data.password"
                    :placeholder="$t('auth.password')"
                  />
                </div>
                <!--End Password-->
                <!--Login Button-->
                <button class="main_button button" ref="reset">
                  <i class="fas fa-spinner"></i>
                  {{ $t("sendBtn") }}
                </button>
                <!--Login Button-->
              </form>
            </div>
            <!--End Inputs Part-->
          </div>
          <!--End Auth Page-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //START INPUTS DATA
      data: {
        password: null,
        passwordConfirmation: null,
      },
      //END INPUTS DATA
    };
  },
  methods: {
    // Start Login
    reset() {
      const submitButton = this.$refs["reset"];
      submitButton.classList.add("click");
      if (this.data.password == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.password"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        const data = new FormData();
        data.append("phone", this.userPhone);
        data.append("code", this.devMessage);
        data.append("password", this.data.password);
        data.append("password_confirmation", this.data.passwordConfirmation);
        this.$axios
          .post("/reset_password", data)
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$router.push({ path: "/login" });
              this.$iziToast.success({
                timeout: 2000,
                message: "تم اعادة تعين كلمة المرور بنجاح",
                position: "bottomRight",
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
    //End Login
  },
  computed: {
    devMessage() {
      return this.$store.getters["auth_module/devMessage"];
    },
    userPhone() {
      return this.$store.getters["auth_module/userPhone"];
    },
  },

  mounted() {
    console.log("UPDATE ", this.$store.getters["auth_module/devMessage"]);
  },
};
</script>
