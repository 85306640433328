<template>
  <div class="my_data">
    <!--Name-->
    <div class="data_info">
      <div class="text_info">
        <span>{{ $t("profile.myData.name") }}</span>
        <h4>{{ myData.first_name }} {{ myData.last_name }}</h4>
      </div>
      <div class="edit_info">
        <router-link to="/editData"><i class="fas fa-edit"></i></router-link>
      </div>
    </div>
    <!--Name-->
    <!--Email-->
    <div class="data_info">
      <div class="text_info">
        <span>{{ $t("profile.myData.email") }}</span>
        <h4 v-if="myData.email != null">{{ myData.email }}</h4>
        <h4 v-else>{{ $t("notFound") }}</h4>
      </div>
    </div>
    <!--Email-->
    <!--Phone-->
    <!-- <div class="data_info">
      <div class="text_info">
        <span>{{ $t("profile.myData.phone") }}</span>
        <h4>{{ myData.phone }}</h4>
      </div>
    </div> -->
    <!--Phone-->
    <!--Country-->
    <div class="data_info">
      <div class="text_info">
        <span>{{ $t("profile.myData.country") }}</span>
        <h4>{{ myData.country.name }}</h4>
      </div>
    </div>
    <!--Country-->
    <!--City-->
    <div class="data_info">
      <div class="text_info">
        <span>{{ $t("profile.myData.city") }}</span>
        <h4>{{ myData.city.name }}</h4>
      </div>
    </div>
    <!--City-->
    <!--Address-->
    <div class="data_info">
      <div class="text_info">
        <span>{{ $t("profile.myData.address") }}</span>
        <h4>{{ myData.address }}</h4>
      </div>
    </div>
    <!--Address-->
    <!--Password-->
    <div class="data_info">
      <div class="text_info">
        <span>{{ $t("profile.myData.password") }}</span>
        <h4>*********</h4>
      </div>
      <div class="edit_info">
        <router-link to="/editPassword"
          ><i class="fas fa-edit"></i
        ></router-link>
      </div>
    </div>
    <!--Password-->
  </div>
</template>

<script>
export default {
  //START :: MY DATA
  props: ["myData"],
  //End :: MY DATA
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style></style>
