<template>
  <div class="contact_us pad_70 all_bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <div class="main_title with_margin">
            <h3>{{ $t("contact.mainTitle") }}</h3>
          </div>
        </div>
        <div class="col-md-6">
          <form>
            <!--Start Suggest Title-->
            <div class="input_wrapper">
              <input
                class="form-control"
                type="text"
                v-model="data.title"
                :placeholder="$t('contact.title')"
              />
            </div>
            <!--End Suggest Title-->
            <!--Start Suggest Description-->
            <div class="input_wrapper">
              <textarea
                class="form-control"
                type="text"
                v-model="data.desc"
                :placeholder="$t('contact.desc')"
              ></textarea>
            </div>
            <!--End Suggest Description-->
            <!--Send Button-->
            <button
              @click="contact"
              type="button"
              class="main_button button"
              ref="contact"
            >
              <i class="fas fa-spinner"></i>
              {{ $t("sendBtn") }}
            </button>
            <!--Send Button-->
          </form>
          <!--Direct Contact-->
          <div class="direct_contact">
            <p>{{ $t("contact.direct_contact") }}</p>
            <ul class="mb-5">
              <li>
                <a :href="`mailto:${allData.email}`"
                  ><i class="fal fa-envelope"></i
                ></a>
              </li>
              <li>
                <a :href="`tel:${allData.phone}`"
                  ><i class="fal fa-phone"></i
                ></a>
              </li>
              <li>
                <a :href="'https://wa.me/' + allData.whatsapp"
                  ><i class="fab fa-whatsapp"></i
                ></a>
              </li>
            </ul>
            <p>{{ $t("contact.follow_us") }}</p>
            <ul>
              <li>
                <a :href="allData.youtube"><i class="fab fa-youtube"></i></a>
              </li>
              <li>
                <a :href="allData.instagram"
                  ><i class="fab fa-instagram"></i
                ></a>
              </li>
              <li>
                <a :href="allData.twitter"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                <a :href="allData.facebook"><i class="fab fa-facebook"></i></a>
              </li>
            </ul>
          </div>
          <!--Direct Contact-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //START :: CONTACT DATA
      data: {
        title: "",
        desc: "",
      },
      //END :: CONTACT DATA
      //Start Get Data
      allData: null,
      //End Get Data
    };
  },
  methods: {
    //Get Data
    GetData() {
      this.loading = true;
      this.$axios.get("about").then((res) => {
        this.allData = res.data.data;
        this.loading = false;
      });
    },
    //Get Data
    // Start Contact
    contact() {
      const submitButton = this.$refs["contact"];
      submitButton.classList.add("click");
      if (this.data.title == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("contact.validation.title"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.desc == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("contact.validation.desc"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        const data = new FormData();
        data.append("title", this.data.title);
        data.append("content", this.data.desc);
        this.$axios
          .post("/contact", data)
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("sendSuccess"),
                position: "bottomRight",
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
    //End Contact
  },
  mounted() {
    this.GetData();
  },
};
</script>

<style></style>
