<template>
  <div class="rate">
    <ul class="icons">
      <li class="active" v-for="star in rate_num" :key="star">
        <span class="icon"><i class="fas fa-star"></i></span>
      </li>
      <li v-for="star in 5 - rate_num" :key="star + '1'">
        <span class="icon"><i class="fas fa-star"></i></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["rate_num"],

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.rate {
  ul.icons {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      span.icon {
        i {
          font-size: 0.9rem;
          color: var(--footer_link);
        }
      }

      &.active {
        span.icon {
          i {
            color: gold;
          }
        }
      }
    }
  }
}
</style>
