<template>
  <div class="login most_bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <!--Start Auth Page-->
          <div class="auth_form">
            <!--Start Top Part-->
            <div class="auth_top">
              <div class="logo">
                <router-link to="/"
                  ><img src="@/assets/images/Icons/logo.png"
                /></router-link>
              </div>
              <div class="title_content">
                <h4>{{ $t("signup.title") }}</h4>
                <p>{{ $t("signup.desc") }}</p>
              </div>
            </div>
            <!--End Top Part-->
            <!--Start Inputs Part-->
            <div class="auth_inputs mb-3">
              <form>
                <!-- Start Image Upload -->
                <uplode-image
                  class="mb-3"
                  @inputChanged="uplodeImg_1"
                ></uplode-image>
                <!-- End Image Upload -->
                <div class="row">
                  <div class="col-md-12">
                    <!--Start Frist Name-->
                    <div class="input_wrapper">
                      <input
                        class="form-control"
                        type="text"
                        v-model="data.firstName"
                        :placeholder="$t('auth.name')"
                      />
                    </div>
                    <!--End First Number-->
                  </div>
                  <!-- <div class="col-md-6"> -->
                  <!--Start Last Name-->
                  <!-- <div class="input_wrapper">
                      <input
                        class="form-control"
                        type="text"
                        v-model="data.lastName"
                        :placeholder="$t('auth.lastName')"
                      />
                    </div> -->
                  <!--End Last Name-->
                  <!-- </div> -->
                  <div class="col-md-6">
                    <div class="wrapper_flex">
                      <!--Start Phone Number-->
                      <div class="input_wrapper phone_num">
                        <input
                          class="form-control"
                          type="number"
                          v-model="data.phone"
                          :placeholder="$t('auth.phone')"
                        />
                      </div>
                      <!--End Phone Number-->
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                    Start Email
                    <div class="input_wrapper">
                      <input
                        class="form-control"
                        type="email"
                        v-model="data.email"
                        :placeholder="$t('auth.email')"
                      />
                    </div>
                    End Email
                  </div> -->

                  <div class="col-md-6">
                    <!--Start Country-->
                    <div class="input_wrapper phone_code select">
                      <select
                        class="form-control"
                        v-model="data.country"
                        @change="getCities"
                      >
                        <option disabled value="">
                          {{ $t("auth.country") }}
                        </option>
                        <option
                          v-for="country in countries"
                          :key="country.id"
                          :value="country.id"
                        >
                          {{ country.name }}
                        </option>
                      </select>
                    </div>
                    <!--End Country-->
                  </div>

                  <div class="col-md-6">
                    <!--Start City-->
                    <div class="input_wrapper phone_code select">
                      <select class="form-control" v-model="data.city">
                        <option disabled value="">{{ $t("auth.city") }}</option>
                        <option
                          v-for="city in cities"
                          :key="city.id"
                          :value="city.id"
                        >
                          {{ city.name }}
                        </option>
                      </select>
                    </div>
                    <!--End City-->
                  </div>

                  <div class="col-md-6">
                    <!--Start Address-->
                    <div class="input_wrapper">
                      <input
                        class="form-control"
                        type="text"
                        v-model="data.address"
                        :placeholder="$t('auth.address')"
                      />
                    </div>
                    <!--End Address-->
                  </div>
                  <div class="col-md-6">
                    <!--Start Password-->
                    <div class="input_wrapper">
                      <input
                        class="form-control"
                        type="password"
                        v-model="data.password"
                        :placeholder="$t('auth.password')"
                      />
                    </div>
                    <!--End Password-->
                  </div>
                  <div class="col-md-6">
                    <!--Start Password Confirmation-->
                    <div class="input_wrapper">
                      <input
                        class="form-control"
                        type="password"
                        v-model="data.password_confirmation"
                        :placeholder="$t('auth.password_confirmation')"
                      />
                    </div>
                    <!--End Password Confirmation-->
                  </div>
                </div>
                <!--Login Button-->
                <button
                  type="button"
                  class="main_button button mt-3"
                  ref="sign_up"
                  @click="signUp"
                >
                  <i class="fas fa-spinner"></i>
                  {{ $t("auth.signup") }}
                </button>
                <!--Login Button-->
              </form>
            </div>
            <!--End Inputs Part-->
            <!--Start Have Account-->
            <div class="have_account">
              <span class="red_text">{{ $t("signup.haveAccount") }}</span>
              <router-link to="/login" class="second_button">{{
                $t("signup.login")
              }}</router-link>
            </div>
            <!--End Have Account-->
          </div>
          <!--End Auth Page-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // START:: CREATE DATA
      data: {
        avatar: null,
        firstName: null,
        lastName: null,
        phoneCode: null,
        phone: null,
        email: null,
        country: "",
        city: "",
        address: null,
        password: null,
        password_confirmation: null,
      },
      // END:: CREATE DATA

      // Start:: GET COUNTRIES
      countries: [],
      // END:: GET COUNTRIES

      // Start:: GET CITIES
      cities: [],
      // END:: GET CITIES

      // Start:: REGISTER DATA
      registerData: {
        phone: null,
        code: null,
      },
      // END:: REGISTER DATA
    };
  },
  methods: {
    // START:: Uplode Avatar
    uplodeImg_1(obj) {
      this.data.avatar = obj;
      console.log(this.data.avatar);
    },
    // END:: Uplode Avatar

    // START:: Get Countries
    getCountries() {
      this.$axios.get("countries").then((res) => {
        this.countries = res.data.data;
      });
    },
    // END:: Get Countries

    // START:: Get Cities
    getCities() {
      this.$axios.get("cities?country_id=" + this.data.country).then((res) => {
        this.cities = res.data.data;
      });
    },
    // END:: Get Cities

    // START:: Sign Up
    signUp() {
      const submitButton = this.$refs["sign_up"];
      submitButton.classList.add("click");
      if (this.data.avatar == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.image"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.firstName == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.name"),
            position: "bottomRight",
          });
        }, 1000);
      }
      // else if (this.data.lastName == null) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.lastName"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      // else if (this.data.phoneCode == null) {
      //   submitButton.classList.remove("click");
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("auth.validation.phoneCode"),
      //     position: "bottomRight",
      //   });
      // }
      else if (this.data.phone == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.phone"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (String(this.data.phone.length) < 10) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.phoneLength"),
            position: "bottomRight",
          });
        }, 1000);
      }
      // else if (this.data.email == null) {
      //   submitButton.classList.remove("click");
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("auth.validation.email"),
      //     position: "bottomRight",
      //   });
      // }
      else if (this.data.country == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.country"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.city == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.city"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.address == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.address"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.password == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.password"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.password_confirmation == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.password_confirmation"),
            position: "bottomRight",
          });
        });
      } else {
        const data = new FormData();
        data.append("first_name", this.data.firstName);
        data.append("last_name", this.data.lastName);
        data.append("phone", this.data.phone);
        data.append("country_id", this.data.country);
        data.append("city_id", this.data.city);
        data.append("address", this.data.address);
        data.append("password", this.data.password);
        data.append("password_confirmation", this.data.password_confirmation);
        data.append("image", this.data.avatar.img_file);
        data.append("device_type", "android");
        data.append("user_type", "user");
        this.$axios
          .post("/register", data)
          .then(() => {
            this.registerData.phone = this.data.phone;
            console.log(this.registerData);

            this.$store.dispatch("auth_module/register", this.registerData);

            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$router.push({ path: "/activateAccount" });
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("createSuccess"),
                position: "bottomRight",
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
    // END:: Sign Up
  },
  mounted() {
    //Get Countries
    this.getCountries();
  },
};
</script>
