import Vue from "vue";
import VueRouter from "vue-router";

// ============================== Start Views ==============================
import Home from "../views/Home.vue"; // Home

// *************** Children
import HomePage from "../views/children/HomePage.vue";
//Advertisment
import AddAdvertisment from "../views/children/Advertisment/AddAdvertisment.vue";
import EditAdvertisment from "../views/children/Advertisment/EditAdvertisment.vue";
//Last Offers
import LastOffers from "../views/children/LastOffers/LastOffers.vue";
//Category
import Category from "../views/children/LastOffers/Category.vue";
//Sub Category
import SubCategory from "../views/children/LastOffers/SubCategory.vue";
//Single Product
import SingleProduct from "../views/children/Products/SingleProduct.vue";
//Conversation
import Conversation from "../views/children/Conversation/Conversation.vue";
import SingleChat from "../views/children/Conversation/SingleChat.vue";
import OrderProvider from "../views/children/Conversation/OrderProvider.vue";

//Filter
import Filter from "../views/children/Filter/Filter.vue";

//Serach
import Serach from "../views/children/Filter/Search.vue";

/************User*************/
//Favourite
import Favourite from "../views/children/UserProfile/Favourite/Favourite.vue";
//MyAds
import MyAds from "../views/children/UserProfile/MyAds/MyAds.vue";
//Profile
import UserData from "../views/children/UserProfile/UserData/AllData.vue";
//Edit Data
import EditData from "../views/children/UserProfile/UserData/MyData/EditData.vue";
//Edit Password
import EditPassword from "../views/children/UserProfile/UserData/MyData/EditPassword.vue";
//My Orders
import MyOrders from "../views/children/UserProfile/MyOrders/MyOrders.vue";
import OrderDetails from "../views/children/UserProfile/MyOrders/OrderDetails.vue";
//MyPoints
import MyPoints from "../views/children/UserProfile/MyPoints/MyPoints.vue";
//Provider
import Provider from "../views/children/ServiceProvider/AllData.vue";

/********Commission********** */

//Site Commission
import SiteCommission from "../views/children/Commission/Commission.vue";

/************Static Pages*************/
//Terms
import Terms from "../views/children/StaticPages/Terms.vue";
//About
import About from "../views/children/StaticPages/AboutUs.vue";
//Usage Policy
import UsagePolicy from "../views/children/StaticPages/UsagePolicy.vue";
//Faqs
import Faqs from "../views/children/StaticPages/Faqs.vue";
//Contact Us
import ContactUs from "../views/children/StaticPages/ContactUs.vue";

// *************** Start Auth ***************
//Login
import Login from "../views/Auth/Login.vue";
//Sign Up
import SignUp from "../views/Auth/SignUp.vue";
//Activate Account
import ActivateAccount from "../views/Auth/ActivateAccount.vue";
//Forget Password
import ForgetPassword from "../views/Auth/ForgetPassword/ForgetPassword.vue";
// Check Code
import CheckCode from "../views/Auth/ForgetPassword/CheckCode.vue";
// Reset Password
import ResetPassword from "../views/Auth/ForgetPassword/UpdatePassword.vue";
// *************** End Auth ***************

// *************** NotFound
import NotFound from "../views/NotFound.vue";

// ============================== End Views ==============================

// Vuex store
import store from "../store/index";

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    scrollBehavior() {
        document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
    routes: [
        //Main
        {
            path: "/",
            component: Home,
            children: [
                //home
                {
                    path: "/",
                    name: "HomePage",
                    component: HomePage,
                },
                //Advertisment
                {
                    path: "/addAdvertisment",
                    name: "AddAdvertisment",
                    component: AddAdvertisment,
                },

                {
                    path: "/editAdvertisment/:id",
                    name: "EditAdvertisment",
                    component: EditAdvertisment,
                    props: true,
                },
                //LastOffers
                {
                    path: "/lastOffers",
                    name: "LastOffers",
                    component: LastOffers,
                },
                //Category
                {
                    path: "/category/:id",
                    name: "Category",
                    component: Category,
                    props: true,
                },
                //Sub Category
                {
                    path: "/category/:id/subCategory/:subId",
                    name: "SubCategory",
                    component: SubCategory,
                    props: true,
                },
                //Single Product
                {
                    path: "/singleProduct/:id",
                    name: "SingleProduct",
                    component: SingleProduct,
                    props: true,
                },
                // Chat
                {
                    path: "/conversation",
                    name: "Conversation",
                    component: Conversation,
                },
                // Single_chat
                {
                    path: "/singleChat/:id",
                    name: "SingleChat",
                    component: SingleChat,
                    props: true,
                },
                {
                    path: "/filter",
                    name: "Filter",
                    component: Filter,
                },
                {
                    path: "/search/:text",
                    name: "Serach",
                    component: Serach,
                    props: true,
                },
                /********users******/
                //Favourite
                {
                    path: "/favourite",
                    name: "Favourite",
                    component: Favourite,
                },
                //My Ads
                {
                    path: "/myAds",
                    name: "MyAds",
                    component: MyAds,
                },
                //User Data
                {
                    path: "/profile",
                    name: "UserData",
                    component: UserData,
                },
                //Edit Data
                {
                    path: "/editData",
                    name: "EditData",
                    component: EditData,
                },
                //Edit Password
                {
                    path: "/editPassword",
                    name: "EditPassword",
                    component: EditPassword,
                },
                //My Orders
                {
                    path: "/myOrders",
                    name: "MyOrders",
                    component: MyOrders,
                },
                //Order Details
                {
                    path: "/orderDetails/:id",
                    name: "OrderDetails",
                    component: OrderDetails,
                    props: true,
                },
                {
                    path: "/orderProvider/:id",
                    name: "OrderProvider",
                    component: OrderProvider,
                    props: true,
                },
                {
                    path: "/myPoints",
                    name: "MyPoints",
                    component: MyPoints,
                },
                //provider
                {
                    path: "/provider/:id",
                    name: "Provider",
                    component: Provider,
                    props: true,
                },
                /********Commission******/
                //Site Commission
                {
                    path: "/siteCommission",
                    name: "SiteCommission",
                    component: SiteCommission,
                },
                /********Static Pages******/
                //Terms
                {
                    path: "/terms",
                    name: "Terms",
                    component: Terms,
                },
                //About Us
                {
                    path: "/about",
                    name: "About",
                    component: About,
                },
                //Usage Policy
                {
                    path: "/usagePolicy",
                    name: "UsagePolicy",
                    component: UsagePolicy,
                },
                //Faqs
                {
                    path: "/faqs",
                    name: "Faqs",
                    component: Faqs,
                },
                //Usage Policy
                {
                    path: "/contactUs",
                    name: "ContactUs",
                    component: ContactUs,
                },
            ],
        },

        // Auth
        {
            path: "/login",
            name: "Login",
            component: Login,
        },
        {
            path: "/signUp",
            name: "SignUp",
            component: SignUp,
        },
        {
            path: "/activateAccount",
            name: "ActivateAccount",
            component: ActivateAccount,
        },
        {
            path: "/forgetPassword",
            name: "ForgetPassword",
            component: ForgetPassword,
        },
        {
            path: "/checkCode",
            name: "CheckCode",
            component: CheckCode,
        },
        {
            path: "/resetPassword",
            name: "ResetPassword",
            component: ResetPassword,
        },

        // NotFound
        { path: "/:notFound(.*)", name: "NotFound", component: NotFound },
    ],
});

router.beforeEach((to, _2, next) => {
    // if (to.name == "Terms" && to.name == ) {
    //     next();
    // } else if (
    //     to.name != "Login" &&
    //     to.name != "SignUp" &&
    //     to.name != "ActivateAccount" &&
    //     to.name != "ForgetPassword" &&
    //     to.name != "CheckCode" &&
    //     to.name != "ResetPassword" &&
    //     !store.getters["auth_module/token"]
    // ) {
    //     console.log("not auth ", to.name);
    //     next({ name: "Login" });
    // } 
    if (
        (to.name == "Login" ||
            to.name == "SignUp" ||
            to.name == "ActivateAccount" ||
            to.name == "ForgetPassword" ||
            to.name == "CheckCode" ||
            to.name == "ResetPassword") &&
        store.getters["auth_module/token"]
    ) {
        console.log("auth ", to.name);
        next("/");
    } else if ((to.name == "Favourite" || to.name == "AddAdvertisment" || to.name == "MyPoints" || to.name == "MyOrders" || to.name == "EditData" || to.name == "MyAds" || to.name == "UserData" || to.name == "EditPassword" || to.name == "EditAdvertisment" || to.name == "Conversation" || to.name == "SingleChat") &&
        !store.getters["auth_module/token"]) {
        next("/");
    } else {
        next();
    }
});

Vue.use(VueRouter);
export default router;