<template>
  <div class="pagenation" v-if="last_page > 1">
    <ul>
      <li
        v-for="index in last_page"
        :class="index === currentPage ? 'active' : ''"
        :key="index"
        @click="indexNumber(index)"
      >
        {{ index }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["last_page"],
  data() {
    return {};
  },
  methods: {
    // pagenation
    indexNumber(pageNumber) {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      if (this.currentPage !== pageNumber) {
        this.currentPage = pageNumber;
        this.$emit("getData");
      }
    },
    // pagenation
  },
};
</script>

<style lang="scss" scoped>
.pagenation {
  margin: auto;
  ul {
    padding: 0;
    text-align: center;
    direction: ltr;
    margin-bottom: 30px;
    li {
      display: inline-flex;
      margin: 0 5px;
      width: 35px;
      height: 35px;
      background: #354128;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-radius: 50px;
      font-size: 15px;
      border: 1px solid #354128;
      cursor: pointer;
      transition: 0.5s;
      opacity: 0.4;
      line-height: 0;
      &.active {
        background: #354128;
        color: #fff;
        opacity: 1;
      }
      &:hover {
        background: #354128;
        color: #fff;
        transition: 0.5s;
        opacity: 1;
      }
    }
  }
}
</style>
