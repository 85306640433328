<template>
  <!-- loader -->
  <div class="product_loader">
    <div class="row">
      <div class="col-md-3">
        <div class="loader_container">
          <div class="animated_background product_image"></div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="loader_container">
          <div class="animated_background product_image"></div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="loader_container">
          <div class="animated_background product_image"></div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="loader_container">
          <div class="animated_background product_image"></div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="loader_container">
          <div class="animated_background product_image"></div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="loader_container">
          <div class="animated_background product_image"></div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="loader_container">
          <div class="animated_background product_image"></div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="loader_container">
          <div class="animated_background product_image"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
