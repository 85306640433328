<template>
  <SliderLoader v-if="loading"></SliderLoader>
  <div class="slider_container images" v-else>
    <!-- Actions -->
    <div class="custom_arrows inside">
      <button class="slick-arrow slick-next" @click="showNext">
        <i class="fas fa-chevron-right"></i>
      </button>
      <button class="slick-arrow slick-prev" @click="showPrev">
        <i class="fas fa-chevron-left"></i>
      </button>
    </div>

    <div v-if="slides.length != 0">
      <vue-slick class="images" ref="default_carousel" v-bind="settings">
        <div v-for="slide in slides" :key="slide.id" class="my_slide">
          <!--Slide Main Image-->
          <img :src="slide.image" />
          <!--Slide Content-->
          <div class="content_container">
            <div class="content">
              <!--Slide Title-->
              <!-- <h2>{{ slide.name }}</h2> -->
              <!--Slide Description-->
              <!-- <h3>
              {{ slide.desc }}
            </h3> -->
              <!--Slide Content Image-->
              <img :src="slide.image" />
            </div>
          </div>
        </div>
      </vue-slick>
    </div>
    <div v-else>
      <vue-slick class="images" ref="default_carousel" v-bind="settings">
        <div class="my_slide">
          <!--Slide Main Image-->
          <img src="@/assets/images/slider/bg.png" />
          <!--Slide Content-->
          <div class="content_container">
            <div class="content">
              <!--Slide Title-->
              <!-- <h2>{{ slide.name }}</h2> -->
              <!--Slide Description-->
              <!-- <h3>
              {{ slide.desc }}
            </h3> -->
              <!--Slide Content Image-->
              <img src="@/assets/images/slider/phone.png" />
            </div>
          </div>
        </div>
      </vue-slick>
    </div>
  </div>
</template>

<script>
import SliderLoader from "../Loaders/MainSlider.vue";
export default {
  data() {
    return {
      //Slider Data
      slides: [],
      //Slider Data

      settings: {
        arrows: true,
        dots: true,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        initialSlide: 2,
        // responsive: [
        //   {
        //     breakpoint: 1024,
        //     settings: {
        //       slidesToShow: 3,
        //       slidesToScroll: 1,
        //     },
        //   },
        //   {
        //     breakpoint: 600,
        //     settings: {
        //       slidesToShow: 2,
        //       slidesToScroll: 1,
        //       initialSlide: 2,
        //     },
        //   },
        //   {
        //     breakpoint: 480,
        //     settings: {
        //       slidesToShow: 1,
        //       slidesToScroll: 1,
        //     },
        //   },
        // ],
      },

      //Loading
      loading: false,
      //Loading
    };
  },

  methods: {
    //Show Next Slide
    showNext() {
      this.$refs.default_carousel.next();
    },
    //Show Prev Slide
    showPrev() {
      this.$refs.default_carousel.prev();
    },

    //Get Slider
    getSlider() {
      this.loading = true;
      this.$axios.get("/sliders").then((res) => {
        this.loading = false;
        this.slides = res.data.data;
      });
    },
    //Get Slider
  },

  created() {
    this.getSlider();
  },

  components: {
    SliderLoader,
  },
};
</script>
