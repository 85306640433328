<template>
  <div class="homePage">
    <Slider></Slider>
    <Products></Products>
  </div>
</template>

<script>
import Slider from "@/components/HomePage/Slider.vue";
import Products from "@/components/HomePage/Products/Products.vue";
export default {
  data() {
    return {};
  },
  methods: {
    changeTheme() {
      this.$store.dispatch("theme_module/changeTheme");
      if (this.$store.getters["theme_module/current_theme"] == "light") {
        this.$vuetify.theme.dark = false;
      } else {
        this.$vuetify.theme.dark = true;
      }
    },

    changeLang() {
      this.$store.dispatch("lang_module/switchLang");
    },
  },
  components: {
    Slider,
    Products,
  },
};
</script>
