<template>
  <div class="static_pages pad_70 all_bg">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="main_title with_margin">
            <h3>{{ $t("static.usagePolicy") }}</h3>
          </div>
          <data-loader v-if="loading"></data-loader>
          <div class="all_text" v-else>
            <p>{{ allData.policy }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //Start Get Data
      allData: null,
      //End Get Data
      //Start Loading
      loading: false,
      //End Loading
    };
  },
  methods: {
    //Get Data
    GetData() {
      this.loading = true;
      this.$axios.get("policy").then((res) => {
        this.allData = res.data.data;
        this.loading = false;
      });
    },
    //Get Data
  },
  mounted() {
    this.GetData();
  },
};
</script>

<style></style>
