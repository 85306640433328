<template>
  <div class="last_offers all_bg pad_50">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="main_title with_margin">
            <h3>{{ $t("filter.title") }}</h3>
          </div>
        </div>
      </div>
      <product-loader v-if="loading"></product-loader>
      <div class="row" v-else>
        <no-data v-if="products.length == 0"></no-data>
        <template v-else>
          <div class="col-md-3" v-for="product in products" :key="product.id">
            <ProductCard :productItem="product"></ProductCard>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/HomePage/Products/ProductCard.vue";
export default {
  data() {
    return {
      //Start Products
      products: [],
      //End Products

      //Start Loading
      loading: false,
      //End Loading
    };
  },
  methods: {
    //Get Products
    getProducts() {
      this.loading = true;
      this.$axios
        .get(
          "products?category_id=" +
            this.filterObj.category +
            "&city_id=" +
            this.filterObj.city +
            "&price_from=" +
            this.filterObj.priceFrom +
            "&price_to=" +
            this.filterObj.priceTo
        )
        .then((res) => {
          this.products = res.data.data;
          this.loading = false;
        });
    },
    //Get Products
  },
  components: {
    ProductCard,
  },
  computed: {
    filterObj() {
      return this.$store.getters["filter_module/filterObj"];
    },
  },
  mounted() {
    this.getProducts();
  },
};
</script>

<style></style>
