<template>
  <div class="chat_page">
    <chat-comp v-if="chatObj" :chatObj="chatObj"></chat-comp>
  </div>
</template>

<script>
import ChatComp from "@/components/Global/ChatComp.vue";

export default {
  components: {
    ChatComp,
  },

  props: ["id"],

  data() {
    return {
      chatObj: null,
    };
  },

  methods: {
    getChat() {
      this.$axios.get(`client/chat/details?id=${this.id}`).then((res) => {
        this.chatObj = res.data.data;
      });
    },
  },

  created() {
    this.getChat();
  },
};
</script>
