<template>
  <data-loader v-if="loading"></data-loader>
  <div class="order_details pad_70 all_bg" v-else>
    <div class="container">
      <div class="row" v-if="orderObj">
        <div class="col-sm-12">
          <div class="main_title with_margin">
            <h3>{{ $t("profile.myOrders.title") }}</h3>
          </div>
        </div>

        <div class="col-md-6">
          <!--Start Order Details-->
          <div class="order_details_card">
            <span class="title">{{ $t("profile.myOrders.orderDetails") }}</span>
            <div class="details_card">
              <div class="flex_order">
                <div class="order_info">
                  <div class="image">
                    <img
                      @error="setDefultImage"
                      :src="
                        orderObj.product.image
                          ? orderObj.product.image.link
                          : ''
                      "
                    />
                  </div>
                  <div class="order_exp">
                    <h4>{{ orderObj.product.name }}</h4>
                    <span>{{ orderObj.product.category.name }}</span>
                  </div>
                </div>
                <div class="order_status">
                  <span v-if="orderObj.status == 'pending'">{{
                    $t("orderStatus.pending")
                  }}</span>
                  <span v-if="orderObj.status == 'waiting'">{{
                    $t("orderStatus.waiting")
                  }}</span>
                  <span v-if="orderObj.status == 'on_way'">{{
                    $t("orderStatus.on_way")
                  }}</span>
                  <span v-if="orderObj.status == 'arrive'">{{
                    $t("orderStatus.arrive")
                  }}</span>
                  <span v-if="orderObj.status == 'finished'">{{
                    $t("orderStatus.finished")
                  }}</span>
                  <span v-if="orderObj.status == 'cancelled'">{{
                    $t("orderStatus.cancelled")
                  }}</span>
                  <span v-if="orderObj.status == 'completed'">{{
                    $t("orderStatus.completed")
                  }}</span>
                  <span v-if="orderObj.status == 'refused'">{{
                    $t("orderStatus.refused")
                  }}</span>
                  <span v-if="orderObj.status == 'accepted'">{{
                    $t("orderStatus.accepted")
                  }}</span>
                </div>
              </div>
              <hr class="line" />
              <div class="order_user">
                <div class="user_info">
                  <div class="image">
                    <img
                      @error="setDefultImage"
                      :src="orderObj.product.provider.image"
                    />
                  </div>
                  <div class="det">
                    <h4>
                      {{
                        orderObj.product.provider.first_name +
                        orderObj.product.provider.last_name
                      }}
                    </h4>
                    <Rate :rate_num="orderObj.product.provider.rates"></Rate>
                  </div>
                </div>
                <div class="user_contact">
                  <ul>
                    <li class="call">
                      <a :href="`tel:${orderObj.product.provider.phone}`"
                        ><i class="far fa-phone"></i
                      ></a>
                    </li>
                    <li class="caht">
                      <button @click="createCaht">
                        <i class="far fa-comment-dots"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--End Order Details-->

          <!--Start Order Summary-->
          <div v-if="!(orderObj.status == 'pending')" class="order_summary">
            <span class="title">{{ $t("profile.orderSummary.title") }}</span>
            <div class="summary_card">
              <div class="flex_summary">
                <span>{{ $t("profile.orderSummary.deliverPrice") }}</span>
                <h5>{{ orderObj.delivery_price }} ر.س</h5>
              </div>
              <div class="flex_summary">
                <span>{{ $t("profile.orderSummary.appFees") }}</span>
                <h5>{{ orderObj.commission }} ر.س</h5>
              </div>
              <div class="flex_summary">
                <span>{{ $t("total") }}</span>
                <h5>{{ orderObj.total }} ر.س</h5>
              </div>
            </div>
          </div>
          <!--End Order Summary-->
        </div>

        <div class="col-md-6">
          <!--Start Offers-->
          <div class="all_offers">
            <span class="title">{{ $t("profile.offers.title") }}</span>

            <!-- If There is Offers -->
            <div class="div" v-if="orderObj.offers.length > 0">
              <div
                class="offer_card"
                v-for="offer in orderObj.offers"
                :key="offer.id"
              >
                <div class="driver_info">
                  <div class="image">
                    <img :src="offer.driver.image" />
                  </div>

                  <div class="exp">
                    <div class="top_exp">
                      <h5>
                        {{ offer.driver.first_name + offer.driver.last_name }}
                      </h5>
                      <Rate :rate_num="offer.driver.rates"></Rate>
                    </div>
                    <div class="price">
                      <h5>{{ offer.price }} ر.س</h5>
                    </div>
                  </div>
                </div>

                <div
                  class="offer_action"
                  v-if="
                    orderObj.status == 'pending' && offer.status == 'pending'
                  "
                >
                  <button
                    @click="acceptOffer(offer.id)"
                    class="btn accept_btn"
                    type="button"
                  >
                    {{ $t("accept") }}
                  </button>

                  <button
                    @click="refuseOffer(offer.id)"
                    class="btn reject_btn"
                    type="button"
                  >
                    {{ $t("reject") }}
                  </button>
                </div>
              </div>
            </div>

            <!-- If Offers Empty -->
            <div v-else class="offer_card ext-center">
              <h5>{{ $t("noOffers") }}</h5>
            </div>
          </div>
          <!--End Offers-->
        </div>

        <!--Start Cancel Btn-->
        <div
          class="col-sm-12"
          v-if="orderObj.status == 'pending' || orderObj.status == 'waiting'"
        >
          <div @click="cancelOrder" class="bottom_cancel">
            <button class="cancel_btn" type="button">
              {{ $t("cancelOrder") }}
            </button>
          </div>
        </div>
        <!--End Cancel Btn-->
      </div>
    </div>

    <!--Base Model-->
    <base-model :show="show" @closeModel="closeModel">
      <div class="oreder_accepted">
        <div class="icon">
          <img src="@/assets/images/Icons/success.png" />
        </div>
        <div class="exp">
          <p>{{ $t("driverOfferAccept") }}</p>
          <p>{{ $t("followOrder") }}</p>
        </div>
        <div class="bottom_btns">
          <router-link to="/" class="go_home">{{
            $t("navbar.home")
          }}</router-link>
          <router-link to="/myOrders" class="go_orders">{{
            $t("navbar.profile.myOrders")
          }}</router-link>
        </div>
      </div>
    </base-model>
    <!--Base Model-->
  </div>
</template>

<script>
export default {
  props: ["id"],

  data() {
    return {
      // START:: SHOW MODEL
      show: false,
      // END:: SHOW MODEL

      orderObj: null,

      //START:: LOADING
      loading: false,
      //END:: LOADING
    };
  },
  methods: {
    // START:: HIDE MODEL
    closeModel() {
      this.show = false;
    },
    // END:: HIDE MODEL

    //Replace Image
    setDefultImage(e) {
      e.target.src = require("@/assets/images/Icons/hidden-image.png");
    },
    //Replace Image

    GetOrder() {
      this.loading = true;
      this.$axios.get(`client/order/${this.id}`).then((res) => {
        this.orderObj = res.data.data;
        this.loading = false;
      });
    },

    acceptOffer(offerID) {
      this.$axios({
        method: "post",
        url: "client/accept-offer",
        data: {
          offer_id: offerID,
          order_id: this.orderObj.product.id,
        },
      })
        .then(() => {
          this.show = true;
          this.GetOrder();
        })
        .catch((err) => {
          this.$iziToast.error({
            message: err.response.data.message,
          });
        });
    },

    refuseOffer(offerID) {
      this.$axios({
        method: "post",
        url: "client/refuse-offer",
        data: {
          offer_id: offerID,
          order_id: this.orderObj.product.id,
        },
      })
        .then(() => {
          this.GetOrder();
          this.$iziToast.succes({
            message: "تم رفض العرض ",
          });
        })
        .catch((err) => {
          this.$iziToast.error({
            message: err.response.data.message,
          });
        });
    },

    cancelOrder() {
      this.$axios({
        method: "post",
        url: "client/cancel-order",
        data: {
          order_id: this.orderObj.id,
        },
      })
        .then(() => {
          this.$router.push("/myOrders");
          this.$iziToast.succes({
            message: "تم الغاء الطلب",
          });
        })
        .catch((err) => {
          this.$iziToast.error({
            message: err.response.data.message,
          });
        });
    },

    //Create Chat
    createCaht() {
      const data = new FormData();
      data.append("provider_id", this.orderObj.product.provider.id);
      data.append("product_id", this.orderObj.product.id);
      data.append("type", "order");
      this.$axios.post("client/chat", data).then((res) => {
        this.$router.push({ path: `/singleChat/ + ${res.data.data.id}` });
      });
    },
    //Create Chat
  },

  created() {
    this.GetOrder();
  },
};
</script>

<style></style>
