<template>
  <div class="single_slider" v-if="slider">
    <div class="row" v-if="slider.image.length > 0">
      <div class="col-md-12">
        <div class="slider_for">
          <vue-slick
            ref="c1"
            :asNavFor="c2"
            :focusOnSelect="true"
            :slidesToShow="1"
            :dots="false"
            :draggable="false"
            :arrows="false"
          >
            <div v-for="slide in slider.image" :key="slide.id">
              <img :src="slide.link" />
            </div>
          </vue-slick>
        </div>
      </div>
      <div class="col-md-12">
        <div class="slider_nav">
          <vue-slick
            ref="c2"
            :asNavFor="c1"
            :focusOnSelect="true"
            :slidesToShow="4"
            :dots="false"
            :arrows="false"
            :draggable="false"
          >
            <div v-for="slide in slider.image" :key="slide.id">
              <img :src="slide.link" />
            </div>
          </vue-slick>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-sm-12">
        <div class="slider_for">
          <img class="width_100" src="@/assets/images/Icons/hidden-image.png" />
        </div>
      </div>
    </div>
    <!-- <div class="slider_nav">
      <vue-slick
        ref="c2"
        :asNavFor="c1"
        :focusOnSelect="true"
        :slidesToShow="4"
        :dots="false"
        :arrows="false"
      >
        <div>
          <img src="@/assets/images/products/single/single.png" />
        </div>
        <div>
          <img src="@/assets/images/products/single/single.png" />
        </div>
        <div>
          <img src="@/assets/images/products/single/single.png" />
        </div>
        <div>
          <img src="@/assets/images/products/single/single.png" />
        </div>
      </vue-slick>
    </div>
    <div class="slider_for">
      <vue-slick
        ref="c1"
        :asNavFor="c2"
        :focusOnSelect="true"
        :slidesToShow="1"
        :dots="false"
        :arrows="false"
      >
        <div>
          <img src="@/assets/images/products/single/single.png" />
        </div>
        <div>
          <img src="@/assets/images/products/single/single.png" />
        </div>
        <div>
          <img src="@/assets/images/products/single/single.png" />
        </div>
        <div>
          <img src="@/assets/images/products/single/single.png" />
        </div>
      </vue-slick>
    </div> -->
  </div>
</template>

<script>
export default {
  props: ["slider"],
  data() {
    return {
      items: [1, 2, 3, 4, 5],
      c1: undefined,
      c2: undefined,
    };
  },
  mounted() {
    this.c1 = this.$refs.c1;
    this.c2 = this.$refs.c2;
  },
};
</script>

<style></style>
