// ======== core Vue
import Vue from "vue";

// ======== Main app
import App from "./App.vue";

// ======== Router
import router from "./router";

// ======== Store
import store from "./store";

// ======== language
store.dispatch("lang_module/handleLang"); // ==> Store (Vuex) <==
store.dispatch("theme_module/handleTheme"); // ==> Store (Vuex) <==

// ========  Start Axios
import axios from "axios";

Vue.prototype.$axios = axios; // Glopal variable

// Global headers
axios.defaults.baseURL = "https://mnfaah.mnfaah.com/api/"; // baseurl
axios.defaults.headers.common["cache-control"] = "no-cache";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Accept-language"] =
    store.getters["lang_module/lang"]; // ==> Store (Vuex) <==
axios.defaults.headers.common["lang"] =
    store.getters["lang_module/lang"]; // ==> Store (Vuex) <==

// Set Token If Exists
if (store.getters["auth_module/token"]) {
    axios.defaults.headers.common["Authorization"] =
        "bearer" + localStorage.getItem("Manfaa_App_Token");
}

// ========  End Axios

// ======== Bootstrap
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
Vue.use(BootstrapVue);

// ======== iziToast
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.min.css";
Vue.prototype.$iziToast = iziToast; // Glopal variable
Vue.use(iziToast);

// ======== AudioRecorder
import AudioRecorder from "vue-audio-recorder";
Vue.use(AudioRecorder);

// =============> VueChatScroll
import VueChatScroll from "vue-chat-scroll";
Vue.use(VueChatScroll);

// ======== Moment
const moment = require("moment");
if (store.getters["lang_module/lang"] == "ar") {
    require("moment/locale/ar");
}
Vue.use(require("vue-moment"), {
    moment,
});

// ======== Vue Social Sharing
import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

// ======== AOS
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

// ======== i18n
import i18n from "./i18n";

// ======= vuetify
import vuetify from "./plugins/vuetify";

// =============> Vue Slike Carousal
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

// ========  Main Style
import "./assets/scss/main.scss";

// =============> Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
    load: {
        // Add your API key here
        key: "AIzaSyBrYuQMO2XmeN6Gf06wexUVh6r8urxKXXA",
        libraries: "places", // This is required if you use the Auto complete plug-in
    },
    // load: {
    //     // Add your API key here
    //     key: "AIzaSyBI8-9ej_tnz03I0yEW-irNptyIkZ23FSs",
    //     libraries: "places", // This is required if you use the Auto complete plug-in
    // },
});

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);


// =============> Multiselect
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
Vue.component("multiselect", Multiselect);

// Import Globbal Components

//tabs
import Tabs from "@/components/Global/Tabs.vue";
//Upload Image
import UplodeImage from "./components/Uploads/UplodeImage.vue";
//Rate
import Rate from "./components/Global/Rate.vue";
//Base Model
import BaseModel from "./components/UI/BaseModel.vue";
//Product Loader
import ProductLoader from "./components/Loaders/ProductLoader.vue";
//Data Not Found
import DataNotFound from "./components/Global/NotFound.vue";
//Data Laoder
import DataLoader from "./components/Loaders/DataLoader.vue";
//Pagenation
import Pagenation from "./components/Global/Pagenation.vue";
// Register Globbal Components
//slick carousel
Vue.component("vue-slick", VueSlickCarousel);
//tabs
Vue.component("myTabs", Tabs);
//Upload Image
Vue.component("uplode-image", UplodeImage);
//Rate
Vue.component("Rate", Rate);
//Base Model
Vue.component("base-model", BaseModel);
//Product Loader
Vue.component("product-loader", ProductLoader);
//Data Not Found
Vue.component("no-data", DataNotFound);
//Data Laoder
Vue.component("data-loader", DataLoader);
//Pagenation
Vue.component("pagenation", Pagenation);

// ======== Attatch and Mount
Vue.config.productionTip = false;
new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");