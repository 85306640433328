import { render, staticRenderFns } from "./OrderProvider.vue?vue&type=template&id=2543bdfd&scoped=true&"
import script from "./OrderProvider.vue?vue&type=script&lang=js&"
export * from "./OrderProvider.vue?vue&type=script&lang=js&"
import style0 from "./OrderProvider.vue?vue&type=style&index=0&id=2543bdfd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2543bdfd",
  null
  
)

export default component.exports