<template>
  <div class="add_elan">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <div class="main_title">
            <h3 class="mb-6">{{ $t("advertisment.add.title") }}</h3>
            <p>{{ $t("advertisment.add.desc") }}</p>
          </div>
        </div>

        <div class="col-md-6">
          <form>
            <!-- Start Image Upload -->
            <uplode-multiple @inputChanged="images_upolded"></uplode-multiple>
            <!-- End Image Upload -->

            <!-- Start Product Name Ar -->
            <div class="input_wrapper">
              <input
                class="form-control"
                type="text"
                v-model="data.productNameAr"
                :placeholder="$t('advertisment.add.productNameAr')"
              />
            </div>
            <!-- End Product Name Ar -->

            <!-- Start Product Name En -->
            <!-- <div class="input_wrapper">
              <input
                class="form-control"
                type="text"
                v-model="data.productNameEn"
                :placeholder="$t('advertisment.add.productNameEn')"
              />
            </div> -->
            <!-- End Product Name En -->

            <!-- Start Category -->
            <div class="input_wrapper phone_code select">
              <select
                @change="getSubCategories(data.category_id, 0)"
                class="form-control"
                v-model="data.category_id"
              >
                <option disabled value="">
                  {{ $t("advertisment.add.category") }}
                </option>
                <option
                  v-for="item in categories"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <!-- End Category -->

            <!-- Start Sub Category1 -->
            <div
              class="input_wrapper phone_code select"
              v-if="subCatTree1.length > 0"
            >
              <select
                @change="getSubCategories(data.subcategory_id, 1)"
                class="form-control"
                v-model="data.subcategory_id"
              >
                <option disabled value="">
                  {{ $t("advertisment.add.category") }}
                </option>
                <option
                  v-for="item in subCatTree1"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <!-- End Sub Category1 -->

            <!-- Start Sub Category2 -->
            <div
              class="input_wrapper phone_code select"
              v-if="subCatTree2.length > 0"
            >
              <select
                @change="getSubCategories(data.subcategory_id2, 2)"
                class="form-control"
                v-model="data.subcategory_id2"
              >
                <option disabled value="">
                  {{ $t("advertisment.add.category") }}
                </option>
                <option
                  v-for="item in subCatTree2"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <!-- End Sub Category2 -->

            <!-- Start Sub Category3 -->
            <div
              class="input_wrapper phone_code select"
              v-if="subCatTree3.length > 0"
            >
              <select
                @change="getSubCategories(data.subcategory_id3, 3)"
                class="form-control"
                v-model="data.subcategory_id3"
              >
                <option disabled value="">
                  {{ $t("advertisment.add.category") }}
                </option>
                <option
                  v-for="item in subCatTree3"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <!-- End Sub Category3 -->

            <!-- Start Sub Category4 -->
            <div
              class="input_wrapper phone_code select"
              v-if="subCatTree4.length > 0"
            >
              <select
                @change="getSubCategories(data.subcategory_id4, 4)"
                class="form-control"
                v-model="data.subcategory_id4"
              >
                <option disabled value="">
                  {{ $t("advertisment.add.category") }}
                </option>
                <option
                  v-for="item in subCatTree4"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <!-- End Sub Category4 -->

            <!-- Start Show Name Or Not -->
            <div class="input_wrapper" v-if="data.category_id == 3">
              <h4>{{ $t("advertisment.add.question") }}</h4>
              <ul class="radio_list">
                <li>
                  <input
                    type="radio"
                    name="ques"
                    value="false"
                    id="ques1"
                    v-model="radioGroup"
                  />
                  <label for="ques1">{{ $t("advertisment.add.yes") }}</label>
                </li>
                <li>
                  <input
                    type="radio"
                    name="ques"
                    value="true"
                    id="ques2"
                    v-model="radioGroup"
                  />
                  <label for="ques2">{{ $t("advertisment.add.no") }}</label>
                </li>
              </ul>
            </div>
            <!-- End Show Name Or Not -->

            <!-- Start Sub Category -->
            <!-- <template v-if="currentSubCategoriesSelectList.length > 0">
              <div
                class="input_wrapper phone_code"
                v-for="(item, index) in currentSubCategoriesSelectList"
                :key="index"
              >
                <multiselect
                  v-model="selectedSubCategories[index]"
                  :options="currentSubCategoriesSelectList[index]"
                  class="form_control"
                  label="name"
                  track-by="id"
                  :placeholder="$t('advertisment.add.subCategory')"
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                  :open-direction="'bottom'"
                  @input="selectSubcategory(index)"
                  dir="rtl"
                >
                </multiselect>
              </div>
            </template> -->

            <!-- <template>
              <div
                class="input_wrapper phone_code select"
                v-for="(sub, index) in subItems"
                :key="sub.id"
              > -->
            <!-- <h4 dir="ltr"><pre>{{subItems[index].subcategory}}</pre></h4> -->
            <!-- <multiselect
                  v-model="sub_categories"
                  :options="subItems[index].subcategory"
                  label="name"
                  track-by="id"
                  :placeholder="$t('advertisment.add.subCategory')"
                  :searchable="true"
                  :allow-empty="false"
                  :show-labels="false"
                  :open-direction="'bottom'"
                  v-if="subItems[index].subcategory.length > 0"
                  @input="
                    setSubElement(index, subItems[index]);
                    spliceArray(index);
                  "
                >
                </multiselect> -->

            <!-- <select
                  class="form-control"
                  v-model="sub_categories"
                  v-if="subItems[index].subcategory.length > 0"
                  @change="spliceArray(index, subItems[index])"
                >
                  <option disabled value="">
                    {{$t("advertisment.add.subCategory")}}
                  </option>

                  <option
                    v-for="item in subItems[index].subcategory"
                    :key="item.id"
                    :value="item"
                  >
                    {{ item.name }}
                  </option>
                </select> -->
            <!-- </div>
            </template> -->
            <!-- End Sub Category -->

            <!-- Start Countries -->
            <div class="input_wrapper phone_code select">
              <select
                @change="getCities"
                class="form-control"
                v-model="data.country_id"
              >
                <option disabled value="">
                  {{ $t("advertisment.add.country") }}
                </option>
                <option
                  v-for="item in countries"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <!-- End Countries -->

            <!-- Start cities -->
            <div class="input_wrapper phone_code select">
              <select class="form-control" v-model="data.city_id">
                <option disabled value="">
                  {{ $t("advertisment.add.city") }}
                </option>
                <option v-for="item in cities" :key="item.id" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <!-- End cities -->

            <!-- Start orderDescAr -->
            <div class="input_wrapper">
              <input
                class="form-control"
                type="text"
                v-model="data.orderDescAr"
                :placeholder="$t('advertisment.add.orderDescAr')"
              />
            </div>
            <!-- End orderDescAr -->

            <!-- Start orderDescEn -->
            <!-- <div class="input_wrapper">
              <input
                class="form-control"
                type="text"
                v-model="data.orderDescEn"
                :placeholder="$t('advertisment.add.orderDescEn')"
              />
            </div> -->
            <!-- End orderDescEn -->

            <!-- Start Price -->
            <div class="input_wrapper" v-if="data.category_id != 3">
              <input
                class="form-control"
                type="number"
                v-model="data.price"
                :placeholder="$t('advertisment.add.price')"
              />
            </div>
            <!-- End Price -->

            <!-- Start Product Address -->
            <div class="input_wrapper">
              <input
                type="text"
                class="form-control"
                aria-describedby="searchMap"
                :placeholder="
                  data.address ? data.address : $t('advertisment.add.address')
                "
                id="autocomplete_ar"
                @click="getAddress"
              />
            </div>
            <!-- End Product Address -->

            <!-- Start Product Status -->
            <div class="input_wrapper">
              <h4>{{ $t("advertisment.add.productStatus") }}</h4>
              <ul class="budget-state">
                <li class="b-state">
                  <input
                    type="radio"
                    id="1A"
                    value="used"
                    v-model="data.productStatus"
                  />
                  <label class="one" for="1A">{{
                    $t("advertisment.add.used")
                  }}</label>
                </li>
                <li class="b-state">
                  <input
                    type="radio"
                    id="1B"
                    value="new"
                    v-model="data.productStatus"
                  />
                  <label class="two" for="1B">{{
                    $t("advertisment.add.new")
                  }}</label>
                </li>
              </ul>
            </div>
            <!-- End Product Status -->

            <!-- Add Button -->
            <button
              @click="addAdvertisment"
              type="button"
              class="main_button button"
              :class="{ disabled: disabled }"
              ref="add"
            >
              <i class="fas fa-spinner"></i>
              {{ $t("add") }}
            </button>
            <!-- Add Button -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBrYuQMO2XmeN6Gf06wexUVh6r8urxKXXA"></script>
<script>
import UplodeMultiple from "../../../components/Uploads/UplodeMultiple.vue";

export default {
  components: { UplodeMultiple },

  data() {
    return {
      btn_loding: false,

      //Start:: SubCategories
      subCategories: [],
      subCatTree1: [],
      subCatTree2: [],
      subCatTree3: [],
      subCatTree4: [],
      subCatTree5: [],
      //End:: SubCategories
      subId: null,

      data: {
        images: [],
        productNameAr: null,
        productNameEn: null,
        category_id: "",
        subcategory_id: "",
        subcategory_id2: "",
        subcategory_id3: "",
        subcategory_id4: "",
        subcategory_id5: "",
        country_id: "",
        city_id: "",
        productStatus: null,
        orderDescAr: null,
        orderDescEn: null,
        price: null,

        // Location
        lng: "",
        lat: "",
        address: "",
      },

      disabled: false,

      //Name Or Not
      radioGroup: null,

      // Fixed Data
      categories: [],
      countries: [],
      cities: [],
      // mainCat: null,
      // sub_categories: "",
      // subItems: [],

      // Siliman Variables
      selectedMainCategory: null,
      currentSubCategoriesSelectList: [],
      selectedSubCategories: [],
    };
  },

  // watch: {
  //   sub_categories(newVal) {
  //     if (this.subItems.find((item) => item.id == newVal.id)) {
  //       let semilarItem = this.subItems.find((item) => item.id == newVal.id);
  //       let itemIndex = this.subItems.indexOf(semilarItem);
  //       this.subItems.splice(itemIndex, this.subItems.length);
  //     }
  //     this.subItems.push(newVal);
  //   },
  // },

  methods: {
    // selectSubcategory(targetIndex) {
    //   if (this.currentSubCategoriesSelectList.length > 1) {
    //     this.currentSubCategoriesSelectList.splice(
    //       targetIndex + 1,
    //       this.currentSubCategoriesSelectList.length
    //     );
    //     this.selectedSubCategories.splice(
    //       targetIndex + 1,
    //       this.selectedSubCategories.length
    //     );
    //   }

    //   this.selectedSubCategories.forEach((element) => {
    //     // ******** Add New Selected Subcategories
    //     if (element.subcategory.length > 0) {
    //       this.currentSubCategoriesSelectList.push(element.subcategory);
    //     }

    //     // ******** Remove Dublicated Items
    //     this.currentSubCategoriesSelectList =
    //       this.currentSubCategoriesSelectList.filter((itemTofFilter, index) => {
    //         return (
    //           this.currentSubCategoriesSelectList.indexOf(itemTofFilter) ===
    //           index
    //         );
    //       });
    //   });
    // },

    getSubCategories(id, subLevel) {
      // this.subCatTree = [];
      this.$axios.get("children_category?category_id=" + id).then((res) => {
        if (subLevel == 0) {
          this.subCatTree1 = res.data.data;
          this.subId = id;
          // if (!this.selectedSubCategories.includes(id)) {
          //   this.selectedSubCategories.push(id);
          // }
          this.subCatTree2 = [];
          this.subCatTree3 = [];
          this.subCatTree4 = [];
          this.subCatTree5 = [];
        } else if (subLevel == 1) {
          this.subCatTree2 = res.data.data;
          this.subId = id;
          if (!this.selectedSubCategories.includes(id)) {
            this.selectedSubCategories.push(id);
          } else if (this.selectedSubCategories.includes(id)) {
            this.selectedSubCategories.pop(id);
            setTimeout(() => {
              this.selectedSubCategories.push(id);
            }, 500);
          }
          this.subCatTree3 = [];
          this.subCatTree4 = [];
          this.subCatTree5 = [];
        } else if (subLevel == 2) {
          this.subCatTree3 = res.data.data;
          this.subId = id;
          if (!this.selectedSubCategories.includes(id)) {
            this.selectedSubCategories.push(id);
          } else if (this.selectedSubCategories.includes(id)) {
            this.selectedSubCategories.pop(id);
            setTimeout(() => {
              this.selectedSubCategories.push(id);
            }, 500);
          }
          this.subCatTree4 = [];
          this.subCatTree5 = [];
        } else if (subLevel == 3) {
          this.subCatTree4 = res.data.data;
          this.subId = id;
          if (!this.selectedSubCategories.includes(id)) {
            this.selectedSubCategories.push(id);
          } else if (this.selectedSubCategories.includes(id)) {
            this.selectedSubCategories.pop(id);
            setTimeout(() => {
              this.selectedSubCategories.push(id);
            }, 500);
          }
          this.subCatTree5 = [];
        } else if (subLevel == 4) {
          this.subCatTree5 = res.data.data;
          this.subId = id;
          if (!this.selectedSubCategories.includes(id)) {
            this.selectedSubCategories.push(id);
          } else if (this.selectedSubCategories.includes(id)) {
            this.selectedSubCategories.pop(id);
            setTimeout(() => {
              this.selectedSubCategories.push(id);
            }, 500);
          }
          this.subCatTree5 = [];
        }
      });
    },

    // setSubElement(index, element) {
    //   console.log(element.subcategory[index]);
    //   console.log(index);

    //   this.subItems.push(element.subcategory[index]);
    //   // if (index > 0) {
    //   // }
    // },

    // spliceArray(targetIndex) {
    //   console.log("TARGET INDEX", targetIndex);
    //   this.subItems.splice(targetIndex + 1, this.subItems.length);
    // },

    images_upolded(imgs) {
      this.data.images = imgs;
    },

    getAddress() {
      var self = this;
      var input = document.getElementById("autocomplete_ar");
      var searchBox = new google.maps.places.SearchBox(input);
      searchBox.addListener("places_changed", function () {
        var places = searchBox.getPlaces();
        if (places.length == 0) {
          return;
        }
        var bounds = new google.maps.LatLngBounds();
        places.forEach(function (place) {
          bounds.extend(place.geometry.location);
          place.geometry.location.lng();
          place.geometry.location.lat();
          place.formatted_address;
          self.data.lng = place.geometry.location.lng();
          self.data.lat = place.geometry.location.lat();
          self.data.address = place.formatted_address;
        });
      });
    },

    // Start Add Advertisment
    addAdvertisment() {
      const submitButton = this.$refs["add"];
      if (this.data.images.length < 1) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("advertisment.validation.images"),
          position: "bottomRight",
        });
      } else if (this.data.productNameAr == null) {
        this.$iziToast.error(
          {
            timeout: 2000,
            message: this.$t("advertisment.validation.productNameAr"),
            position: "bottomRight",
          },
          1000
        );
      }
      // else if (this.data.productNameEn == null) {
      //   this.$iziToast.error(
      //     {
      //       timeout: 2000,
      //       message: this.$t("advertisment.validation.productNameAEn"),
      //       position: "bottomRight",
      //     },
      //     1000
      //   );
      // }
      // else if (this.data.category_id == "") {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("advertisment.validation.category"),
      //     position: "bottomRight",
      //   });
      // } else if (this.data.subcategory_id == "") {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("advertisment.validation.subCategory"),
      //     position: "bottomRight",
      //   });
      // }
      // else if (!this.selectedMainCategory) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("advertisment.validation.category"),
      //     position: "bottomRight",
      //   });
      // } else if (
      //   this.selectedSubCategories[this.selectedSubCategories.length - 1]
      //     .subcategory.length > 0
      // ) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("advertisment.validation.subCategory"),
      //     position: "bottomRight",
      //   });
      // }
      else if (this.data.category_id == "") {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("advertisment.validation.category"),
          position: "bottomRight",
        });
      } else if (this.data.subcategory_id == "") {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("advertisment.validation.subCategory"),
          position: "bottomRight",
        });
      } else if (this.data.country_id == "") {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("advertisment.validation.country"),
          position: "bottomRight",
        });
      } else if (this.data.city_id == "") {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("advertisment.validation.city"),
          position: "bottomRight",
        });
      } else if (this.data.productStatus == null) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("advertisment.validation.productStatus"),
          position: "bottomRight",
        });
      } else if (this.data.orderDescAr == null) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("advertisment.validation.orderDescAr"),
          position: "bottomRight",
        });
      }
      // else if (this.data.orderDescEn == null) {
      //   this.$iziToast.error({
      //     timeout: 2000,
      //     message: this.$t("advertisment.validation.orderDescAr"),
      //     position: "bottomRight",
      //   });
      // }
      else if (this.data.price == null && this.data.category_id != 3) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("advertisment.validation.price"),
          position: "bottomRight",
        });
      } else {
        submitButton.classList.add("click");
        this.disabled = true;

        const my_data = new FormData();
        this.data.images.forEach((img, index) => {
          my_data.append(`images[${index}]`, img.img_file);
        });

        my_data.append("category_id", this.data.category_id);
        my_data.append(
          "subcategory_id",
          this.selectedSubCategories[this.selectedSubCategories.length - 1]
        );

        my_data.append("city_id", this.data.city_id);
        my_data.append("address", this.data.address);
        my_data.append("lat", this.data.lat);
        my_data.append("lng", this.data.lng);
        my_data.append("type", this.data.productStatus);
        my_data.append("ar[name]", this.data.productNameAr);
        my_data.append("en[name]", this.data.productNameAr);
        my_data.append("ar[desc]", this.data.orderDescAr);
        my_data.append("en[desc]", this.data.orderDescAr);
        if (this.data.category_id != 3) {
          my_data.append("price", this.data.price);
        }
        if (this.data.category_id == 3) {
          my_data.append("is_free", +!!this.radioGroup);
        }

        this.$axios
          .post("client/product", my_data)
          .then(() => {
            submitButton.classList.remove("click");

            this.$router.push({ path: "/myAds" });
            this.disabled = false;
            this.$iziToast.success({
              timeout: 2000,
              message: this.$t("addSuccess"),
              position: "bottomRight",
            });
          })
          .catch((err) => {
            submitButton.classList.remove("click");
            this.disabled = false;
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          });
      }
    },
    // End Add Advertisment

    // ==========> GetData
    GetData() {
      // Categories
      this.$axios.get("/categories").then((res) => {
        this.categories = res.data.data;
      });

      // Counrties
      this.$axios.get("/countries").then((res) => {
        this.countries = res.data.data;
      });
    },

    // Sub Categories
    // getSubCategories() {
    //   this.$axios
    //     .get(`children_tree?category_id=${this.data.category_id}`)
    //     .then((res) => {
    //       // console.log("SUBCATS ==>", res.data.data);
    //       this.selectedMainCategory = res.data.data;
    //       this.currentSubCategoriesSelectList = [];
    //       this.currentSubCategoriesSelectList.push([
    //         ...res.data.data.subcategory,
    //       ]);

    //       // this.mainCat = null;
    //       // this.mainCat = res.data.data;
    //       // this.subItems = [];
    //       // this.subItems.push(res.data.data);
    //       // this.sub_categories = res.data.data.subcategory;
    //       // console.log(res.data.data);
    //     });
    // },

    // Sub Categories
    getCities() {
      this.$axios
        .get(`cities?country_id=${this.data.country_id}`)
        .then((res) => {
          this.cities = res.data.data;
        });
    },
  },

  created() {
    this.GetData();
  },

  // computed: {
  //   filteredItems() {
  //     return this.categories.filter((item) => {
  //       const filterId = item.id == this.service.category;
  //       return filterId;
  //     });
  //   },
  // },
};
</script>
