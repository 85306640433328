<template>
  <header class="header">
    <div class="container">
      <nav class="navbar navbar-expand-lg">
        <router-link to="/" class="navbar-brand">
          <div class="logo">
            <img src="@/assets/images/Icons/logo.png" />
          </div>
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="showSideMenu"
        >
          <span class="navbar-toggler-icon"><i class="fal fa-bars"></i></span>
        </button>
        <div class="search_div" :class="{ show: mobileSearch }">
          <input
            v-on:keydown.enter="searchMethod"
            class="form-control"
            v-model="search"
            type="text"
            :placeholder="$t('search')"
          />
          <span class="closePop" @click="showMobileSearch"></span>
        </div>
        <div
          class="con_menu"
          id="navbarSupportedContent"
          :class="{ show: showSide }"
        >
          <ul class="navbar-nav">
            <li class="nav-item active" @click="showNote = false">
              <router-link to="/" class="nav-link">{{
                $t("navbar.home")
              }}</router-link>
            </li>
            <li class="nav-item">
              <button class="nav-link cate" @click="ShowMegaMenu">
                {{ $t("navbar.categories")
                }}<span><i class="far fa-angle-down"></i></span>
              </button>
              <!--Categories Mega Menu-->
              <transition name="fadeIn" mode="in-out">
                <MegaMenu :show="show" @closeMegaMenu="HideMegaMenu"></MegaMenu>
              </transition>
            </li>
          </ul>
          <button class="close_menu" @click="hideSideMenu">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <!--Before Login Part-->
        <div class="login_btn mr-auto" v-if="!token">
          <router-link to="/login">
            <span class="text">{{ $t("navbar.login") }}</span>
            <span class="icon"><i class="far fa-angle-left"></i></span>
          </router-link>
        </div>
        <!--After Login Part-->
        <div class="after_login" v-if="token">
          <ul class="after_ul">
            <!--Notification Icon-->
            <li class="notification_menu">
              <button class="btn" @click="ShowNoteMenu">
                <span class="note_num">{{
                  notifications.unreadnotifications_count
                }}</span>
                <img src="@/assets/images/Icons/note.png" />
              </button>
              <!--Notfication Menu-->
              <transition name="fadeIn">
                <div class="note_menu" v-if="showNote">
                  <span v-if="notifications.notifications.length == 0">
                    {{ $t("notFoundData") }}
                  </span>
                  <ul v-else>
                    <li
                      v-for="item in notifications.notifications"
                      :key="item.id"
                    >
                      <div class="note_details">
                        <div class="icon">
                          <i class="fal fa-bell"></i>
                        </div>
                        <div class="exp">
                          <p>{{ item.title }}</p>
                          <span>{{ item.created_at }}</span>
                        </div>
                      </div>
                      <div class="delete_note">
                        <button
                          class="del_note"
                          type="button"
                          @click="deleteNote(item.id)"
                        >
                          <i class="fal fa-trash-alt"></i>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </transition>
            </li>
            <!--Chat Icon-->
            <li>
              <router-link to="/conversation"
                ><img src="@/assets/images/Icons/chat.png"
              /></router-link>
            </li>
            <!--Add Ads In Mobile-->
            <li class="add_mobile">
              <button @click="ShowQasamModel" type="button" class="ads_link">
                <i class="fas fa-plus"></i>
              </button>
            </li>
            <!--Profile Icon-->
            <li class="profile_menu">
              <button class="btn" @click="ShowProfileMenu">
                <img src="@/assets/images/Icons/Profile.png" />
              </button>
              <!--Profile Menu-->
              <transition name="fadeIn">
                <div class="prof_menu" v-if="showProfile">
                  <ul>
                    <li @click="HideProfileMenu">
                      <router-link class="text" to="/profile">{{
                        $t("navbar.profile.myAccount")
                      }}</router-link>
                      <router-link class="icon" to="/profile"
                        ><span><i class="fas fa-angle-left"></i></span
                      ></router-link>
                    </li>
                    <hr class="line" />
                    <li @click="HideProfileMenu">
                      <router-link class="text" to="/favourite">{{
                        $t("navbar.profile.favourite")
                      }}</router-link>
                      <router-link class="icon" to="/favourite"
                        ><span><i class="fas fa-angle-left"></i></span
                      ></router-link>
                    </li>
                    <hr class="line" />
                    <li @click="HideProfileMenu">
                      <router-link class="text" to="/myOrders">{{
                        $t("navbar.profile.myOrders")
                      }}</router-link>
                      <router-link class="icon" to="/myOrders"
                        ><span><i class="fas fa-angle-left"></i></span
                      ></router-link>
                    </li>
                    <hr class="line" />
                    <li @click="HideProfileMenu">
                      <router-link class="text" to="/myAds">{{
                        $t("navbar.profile.myAds")
                      }}</router-link>
                      <router-link class="icon" to="/myAds"
                        ><span><i class="fas fa-angle-left"></i></span
                      ></router-link>
                    </li>
                    <hr class="line" />
                    <li @click="HideProfileMenu">
                      <router-link class="text" to="/siteCommission">{{
                        $t("navbar.profile.commission")
                      }}</router-link>
                      <router-link class="icon" to="/siteCommission"
                        ><span><i class="fas fa-angle-left"></i></span
                      ></router-link>
                    </li>
                    <hr class="line" />
                    <li @click="HideProfileMenu">
                      <router-link class="text" to="/myPoints">{{
                        $t("navbar.profile.myPoints")
                      }}</router-link>
                      <router-link class="icon" to="/myPoints"
                        ><span><i class="fas fa-angle-left"></i></span
                      ></router-link>
                    </li>
                    <hr class="line" />
                    <li>
                      <button class="logout text" @click="logout">
                        {{ $t("navbar.profile.logout") }}
                      </button>
                      <div class="icon">
                        <span><i class="fas fa-angle-left"></i></span>
                      </div>
                    </li>
                  </ul>
                </div>
              </transition>
            </li>
            <!--Search Icon Visiable On Mobile-->
            <li class="icon_search">
              <button class="btn" @click="showMobileSearch">
                <i class="fal fa-search"></i>
              </button>
            </li>
            <li class="elan_item">
              <button @click="ShowQasamModel" type="button" class="elan_link">
                {{ $t("navbar.add_advert") }}
              </button>
            </li>
          </ul>
        </div>
      </nav>
      <transition name="fadeIn" mode="in-out">
        <div
          class="overlay"
          @click="HideMegaMenu(), HideProfileMenu()"
          v-if="show || showProfile"
        ></div>
      </transition>
    </div>
    <!--Show Qasam-->
    <base-model :show="showQasam" @closeModel="HideQasamModel">
      <div class="qasam">
        <h4>القسم</h4>
        <p v-if="pledge">{{ pledge.policy }}</p>
        <!-- <p>
          قال الله تعالى ( وَأَوْفُوا بِعَهْدِ اللَّهِ إِذَا عَاهَدتُّمْ وَلَا
          تَنقُضُوا الْأَيْمَانَ بَعْدَ تَوْكِيدِهَا وَقَدْ جَعَلْتُمُ اللَّهَ
          عَلَيْكُمْ كَفِيلًا ۚ إِنَّ اللَّهَ يَعْلَمُ مَا تَفْعَلُونَ )
        </p>
        <p>صدق الله العظيم</p>
        <p>
          اتعهد واقسم بالله انا المعلن ان ادفع عمولة الموقع 1% نص ريال فالمية من
          قيمة السلعة في حالة بيعها عن طريق الموقع او بسبب الموقع وان هذه
          العموله
        </p>
        <p>هي امانه في ذمتي</p>
        <p>
          واتعهد انا المعلن ان جميع المعلومات التي سوف اذكرها بالاعلان صحيحة وفي
          القسم الصحيح واتعهد ان الصورة التي سوف يتم عرضها
        </p>
        <p>
          هي صورة حديثة لنفس السلعة وليست سلعه اخرى مشابهه واتعهد انا المعلن ان
          اقوم بدفع العمولة خلال اقل من 5 ايام من تاريخ استلام كامل مبلغ السلعة
        </p> -->
        <button @click="addAdvertisment" class="ok" type="button">
          {{ $t("ok") }}
        </button>
      </div>
    </base-model>
  </header>
</template>

<script>
import MegaMenu from "./MegaMenu.vue";
export default {
  data() {
    return {
      //SHOW MEGA MENU
      show: false,
      //SHOW NOTE MENU
      showNote: false,
      //SHOW PROFILE MENU
      showProfile: false,
      //SHOW QASAM
      showQasam: false,
      //SHOW MOBILE SEARCH
      mobileSearch: false,
      //SHOW SIDE MENU
      showSide: false,
      //SEARCH VALUE
      search: "",
      //PLEDGE
      pledge: null,
    };
  },
  methods: {
    //Show Mega Menu
    ShowMegaMenu() {
      this.show = true;
      this.showNote = false;
    },
    //Hide Mega Menu
    HideMegaMenu() {
      this.show = false;
    },
    //Show Note Menu
    ShowNoteMenu() {
      this.showNote = !this.showNote;
    },
    //Hide Note Menu
    // HideNoteMenu() {
    //   this.showNote = false;
    // },
    //Show Profile Menu
    ShowProfileMenu() {
      this.showProfile = true;
      this.showNote = false;
    },
    //Hide Profile Menu
    HideProfileMenu() {
      this.showProfile = false;
    },
    //Show Qasam
    ShowQasamModel() {
      this.showQasam = true;
      this.showNote = false;
    },
    //Show Qasam
    //Hide Qasam
    HideQasamModel() {
      this.showQasam = false;
    },
    //Hide Qasam
    //Go To Add Advertisment
    addAdvertisment() {
      this.$router.push({ path: "/addAdvertisment" });
      this.HideQasamModel();
    },
    //Go To Add Advertisment
    //Show Mobile Search
    showMobileSearch() {
      this.mobileSearch = !this.mobileSearch;
      this.showNote = false;
    },
    //Hide Mobile Search
    //Show Side Menu
    showSideMenu() {
      this.showSide = true;
      this.showNote = false;
    },
    //Show Side Menu
    //Hide Side Menu
    hideSideMenu() {
      this.showSide = false;
    },
    //Hide Side Menu
    //Search Method
    searchMethod() {
      if (this.search !== "") {
        this.$router.push({
          name: "Serach",
          params: { text: this.search },
        });
        this.showMobileSearch();
      }
    },
    //Search Method
    //Delete Notification
    deleteNote(id) {
      this.$axios.delete("notifications/" + id).then(() => {
        this.$store.dispatch("socket_module/get_notification");
      });
    },
    //Delete Notification
    //Logout
    logout() {
      this.$store.dispatch("auth_module/logOut");
    },
    //Logout
    //Pledge
    getPledge() {
      this.$axios({
        method: "GET",
        url: "comission_policy",
      }).then((res) => {
        this.pledge = res.data.data;
      });
    },
    //Close Menu When Body Click
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.show = false;
        this.showNote = false;
        this.showProfile = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.getPledge();
    if (this.token) {
      this.$store.dispatch("socket_module/get_notification");
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
  components: {
    MegaMenu,
  },
  computed: {
    token() {
      return this.$store.getters["auth_module/token"];
    },
    notifications() {
      return this.$store.getters["socket_module/notification"];
    },
  },
};
</script>

<style lang="scss" scoped></style>
