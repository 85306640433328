<template>
  <data-loader v-if="loading"></data-loader>
  <div class="single_product all_bg pad_70" v-else>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <ProductSlider :slider="product"></ProductSlider>
        </div>
        <div class="col-md-6">
          <ProductDetails :details="product"></ProductDetails>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductSlider from "@/components/SingleProduct/ProductSlider.vue";
import ProductDetails from "@/components/SingleProduct/ProductDetails.vue";
export default {
  props: ["id"],
  data() {
    return {
      //START :: PRODUCT
      product: null,
      //END :: PRODUCT

      //START :: LOADING
      loading: false,
      //END :: LOADING
    };
  },
  components: {
    ProductSlider,
    ProductDetails,
  },
  methods: {
    //Get Single Product
    getProduct() {
      this.loading = true;
      this.$axios.get("client/product/" + this.id).then((res) => {
        this.product = res.data.data;
        this.loading = false;
      });
    },
    //Get Single Product
  },
  mounted() {
    this.getProduct();
  },
};
</script>

<style></style>
