<template>
  <div class="site_commission all_bg pad_50">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <div class="main_title with_margin">
            <h3>{{ $t("commission.title") }}</h3>
          </div>
        </div>
        <div class="col-md-6">
          <!--Start Sell Value-->
          <div class="wrapper_flex">
            <div class="input_wrapper round_radius sel_val">
              <input
                class="form-control"
                @input="calcValue()"
                type="number"
                v-model="data.sellValue"
                :placeholder="$t('commission.sellValue')"
              />
            </div>
            <div class="calc_img">
              <img src="@/assets/images/Icons/calc.png" />
            </div>
          </div>
          <!--End Sell Value-->
          <!--Start Calc Commission Value-->
          <div class="commission_value">
            <p>{{ $t("commission.comValue") }}</p>
            <h5>{{ data.result }} {{ $t("commission.currency") }}</h5>
          </div>
          <!--End Calc Commission Value-->
          <!--Start Pay Commission-->
          <div class="pay_commission">
            <button type="button" @click="payMethods()" class="pay_btn">
              {{ $t("pay") }}
            </button>
          </div>
          <!--End Pay Commission-->
        </div>
      </div>
    </div>

    <!--Choose Pay Way-->
    <base-model :show="data.choosePay" @closeModel="closeChoosePay">
      <div class="end_pay">
        <div class="icon">
          <img src="@/assets/images/commission/salary.png" />
        </div>
        <div class="input_wrapper"></div>
        <div class="bottom_btns">
          <router-link to="/" class="go_home">{{
            $t("navbar.home")
          }}</router-link>
        </div>
      </div>
    </base-model>
    <!--Choose Pay Way-->

    <!--End Pay-->
    <base-model :show="data.endPay" @closeModel="closeModel">
      <div class="end_pay">
        <div class="icon">
          <img src="@/assets/images/commission/salary.png" />
        </div>
        <div class="exp">
          <p>{{ $t("commission.thanks") }}</p>
        </div>
        <div class="bottom_btns">
          <router-link to="/" class="go_home">{{
            $t("navbar.home")
          }}</router-link>
        </div>
      </div>
    </base-model>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // START:: COMMISSION DATA
      data: {
        //sell value
        sellValue: 0,
        //calc result
        result: 0,
        //show model
        endPay: false,
        //Choose Pay
        choosePay: false,
      },
      // END:: COMMISSION DATA

      transId: null,

      //START:: COMMISSION VALUE
      commission: null,
      //END:: COMMISSION VALUE

      //START:: PAYMENT METHODS
      paymentMethods: [],
      //END:: PAYMENT METHODS
    };
  },
  methods: {
    //Show Model
    showModel() {
      this.data.endPay = true;
    },
    //Show Model

    //Hide Model
    closeModel() {
      this.data.endPay = false;
    },
    //Hide Model

    //Hide Model Choose Pay
    closeChoosePay() {
      this.data.choosePay = false;
    },
    //Hide Model Choose Pay

    //Get Commission
    getCommission() {
      this.$axios.get("commission").then((res) => {
        this.commission = res.data.data;
      });
    },
    //Get Commission

    calcValue() {
      this.data.result = Math.ceil(
        this.data.sellValue * (this.commission / 100)
      );
    },

    payMethods() {
      if (!this.data.sellValue && !this.transId) {
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("commission.enterVal"),
          position: "bottomRight",
        });
      } else {
        localStorage.setItem("value_mod", this.data.result);
        window.location.href =
          "https://mnfaah.mnfaah.com/api/client/pay/" +
          this.userId +
          "/" +
          this.data.result +
          "?redirect_url=" +
          encodeURIComponent(
            "https://mnfaah.com/#/siteCommission?amount=" + this.data.result
          );
      }
    },

    pay() {
      // console.log(localStorage.getItem("value_mod"), this.transId);
      let submit_data = new FormData();
      submit_data.append("value", localStorage.getItem("value_mod"));
      submit_data.append("transaction_id", this.transId);
      submit_data.append("payment_type", "visa");
      this.$axios({
        method: "POST",
        url: "client/pay",
        data: submit_data,
      }).then(() => {
        this.showModel();
      });
    },
  },
  watch: {
    // "data.sellValue": function (v) {
    //   this.data.result = Math.ceil(v * (this.commission / 100));
    // },
  },
  computed: {
    userId() {
      return this.$store.getters["auth_module/set_userId"];
    },
  },
  mounted() {
    this.getCommission();
    if (this.data.sellValue) {
      this.calcValue();
    }
    console.log(new URLSearchParams(window.location.href).get("amount"));
    this.transId = new URLSearchParams(window.location.href).get("paymentId");
    // console.log(this.transId);

    if (this.transId) {
      setTimeout(() => {
        this.pay();
      }, 700);
    }
  },
};
</script>

<style></style>
