<template>
  <data-loader v-if="loading"></data-loader>
  <div class="my_points all_bg pad_50" v-else>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <div class="main_title with_margin">
            <h3>{{ $t("profile.myPoints.title") }}</h3>
          </div>
        </div>
        <div class="col-md-6">
          <div class="points_card">
            <span>{{ $t("profile.myPoints.youHave") }}</span>
            <h4>{{ myData.points }} {{ $t("profile.myPoints.point") }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // START:: MY DATA
      myData: null,
      // End:: MY DATA

      // START:: Loading
      loading: false,
      // End:: Loading
    };
  },
  methods: {
    // START:: Get My Data
    getData() {
      this.loading = true;
      this.$axios
        .get("/profile", {
          headers: {
            Authorization: "bearer" + localStorage.getItem("Manfaa_App_Token"),
          },
        })
        .then((res) => {
          this.loading = false;
          this.myData = res.data.data;
        });
    },
    // END:: Get My Data
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
