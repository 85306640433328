<template>
  <div class="mega_menu" v-if="show">
    <div class="row">
      <div class="col-md-4" v-for="category in categories" :key="category.id">
        <div class="single_menu">
          <h3 @click="closeMegaMenu">
            <router-link :to="'/category/' + category.id">{{
              category.name
            }}</router-link>
          </h3>
          <ul class="sub_menu" v-if="category.subcategories">
            <li
              class="sub_menu_item"
              v-for="subCat in category.subcategories"
              :key="subCat.id"
              @click="closeMegaMenu"
            >
              <router-link
                class="sub_menu_link"
                :to="'/category/' + category.id + '/subCategory/' + subCat.id"
              >
                <span class="text">{{ subCat.name }}</span>
                <span class="icon"><i class="fas fa-angle-left"></i></span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: false,
    type: Boolean,
  },
  data() {
    return {
      //START :: CATEGORIES
      categories: [],
      //END :: CATEGORIES
    };
  },
  methods: {
    //Start Get Categories
    getCategories() {
      this.$axios.get("/categories").then((res) => {
        this.categories = res.data.data;
      });
    },
    //End Get Categories

    //Close Mega Menu
    closeMegaMenu() {
      this.$emit("closeMegaMenu");
    },
    //Close Mega Menu
  },
  mounted() {
    this.getCategories();
  },
};
</script>

<style></style>
