<template>
  <div class="orderProvider">
    <!-- Start ========== Map_3 -->
    <div class="map mt-5">
      <!--Map Search-->
      <div class="input_wrapper mb-0">
        <input
          type="text"
          class="form-control"
          id="autocomplete_ar"
          :placeholder="$t('search')"
          @click="getAddressAr"
        />
      </div>
      <!--Map Search-->
      <GmapMap
        :center="center"
        :zoom="12"
        map-type-id="terrain"
        style="width: 100%; height: 600px"
      >
        <GmapMarker
          v-for="(m, index) in markers"
          :key="index"
          :position="m.position"
          :clickable="true"
          :draggable="true"
          @drag="updateCoordinates"
          @click="clickMethod(m)"
        />
      </GmapMap>

      <div class="main_button" @click="createOrder">
        {{ $t("confirmOrder") }}
      </div>
    </div>
    <!-- End ========== Map_3 -->

    <base-model :show="show">
      <div class="oreder_accepted">
        <div class="icon">
          <img src="@/assets/images/Icons/success.png" />
        </div>
        <div class="exp">
          <p>{{ $t("orderAccepted") }}</p>
          <p>{{ $t("willNote") }}</p>
        </div>
        <div class="bottom_btns">
          <router-link to="/" class="go_home">{{
            $t("navbar.home")
          }}</router-link>
          <router-link to="/myOrders" class="go_orders">{{
            $t("navbar.profile.myOrders")
          }}</router-link>
        </div>
      </div>
    </base-model>
  </div>
</template>

<script>
export default {
  props: ["id"],

  data() {
    return {
      // ========== Map
      center: {},
      markers: null,
      coordinates_to_edit: { lat: 0, lng: 0 },

      show: false,
    };
  },

  methods: {
    updateCoordinates(location) {
      this.coordinates_to_edit = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    clickMethod(m) {
      this.center = m.position;
    },

    //Show Model
    showModel() {
      this.show = true;
    },
    //Show Model

    // START:: G-MAP GET ADDRESS
    getAddressAr() {
      var self = this;
      var input = document.getElementById("autocomplete_ar");
      var searchBox = new google.maps.places.SearchBox(input);
      searchBox.addListener("places_changed", function () {
        var places = searchBox.getPlaces();
        if (places.length == 0) {
          return;
        }
        var bounds = new google.maps.LatLngBounds();
        places.forEach(function (place) {
          bounds.extend(place.geometry.location);
          place.geometry.location.lat();
          place.geometry.location.lng();
          place.formatted_address;
          // self.data.address = place.formatted_address;
          self.center.lat = place.geometry.location.lat();
          self.center.lng = place.geometry.location.lng();
          self.coordinates_to_edit.lat = place.geometry.location.lat();
          self.coordinates_to_edit.lng = place.geometry.location.lng();
          // self.markers[0].position.lat = place.geometry.location.lat();
          // self.markers[0].position.lng = place.geometry.location.lat();
        });
      });
    },
    // START:: G-MAP GET ADDRESS

    // ============== Get User Location
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          // this.$axios({
          //   method: "get",
          //   url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyAITrPfT5r_qmCm_8ekZyPmnebGo8o_r18`,
          // })
          //   .then((res) => {
          //     console.log(res.data.results[0].formatted_address);
          //   })
          //   .catch(() => {});
          console.log(position);

          this.markers = [
            {
              position: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            },
          ];
          this.center = this.markers[0].position;
          this.coordinates_to_edit.lat = this.center.lat;
          this.coordinates_to_edit.lng = this.center.lng;
        });
      }
    },

    createOrder() {
      if (
        this.coordinates_to_edit.lat == 0 ||
        this.coordinates_to_edit.lng == 0
      ) {
        this.$iziToast.error({
          message: "يرجي اختبار موقع",
        });
      } else {
        this.$axios({
          method: "post",
          url: "client/order",
          data: {
            product_id: this.id,
            put_lat: this.coordinates_to_edit.lat,
            put_lng: this.coordinates_to_edit.lng,
          },
        })
          .then(() => {
            this.showModel();
          })
          .catch((err) => {
            this.$iziToast.error({
              message: err.response.data.message,
            });
          });
      }
    },

    //Check Product Have Order
    haveOrder() {
      this.$axios
        .get("check-product-order?product_id=" + this.id)
        .then((res) => {
          if (res.data.data == true) {
            this.$router.push({ path: "/" });
          }
        });
    },
    //Check Product Have Order
  },

  created() {
    this.getLocation();
    this.haveOrder();
  },
};
</script>

<style lang="scss" scoped>
.map {
  position: relative;

  .main_button {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
  }
}
</style>
