<template>
  <!-- loader -->
  <div class="slider_loader">
    <div class="loader_container">
      <div class="animated_background slider_image"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
