<template>
  <data-loader v-if="loading"></data-loader>
  <div class="user_profile pad_70 all_bg" v-else>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <!--User Info-->
          <div class="user_info">
            <img :src="myData.image" />
            <h4>{{ myData.first_name }} {{ myData.last_name }}</h4>
            <Rate :rate_num="myData.rates"></Rate>
          </div>
          <!--User Info-->
          <myTabs :tabsContent="tabsContent">
            <template v-slot:myData>
              <div class="row justify-content-center">
                <div class="col-md-8">
                  <MyData :myData="myData"></MyData>
                </div>
              </div>
            </template>
            <template v-slot:rating>
              <div class="row">
                <div class="col-md-12">
                  <no-data v-if="reviews.length == 0"></no-data>
                  <RatingBox :reviews="reviews" v-else></RatingBox>
                </div>
              </div>
            </template>
          </myTabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyData from "./MyData/MyData.vue";
import RatingBox from "./Rating/Rating.vue";
export default {
  data() {
    return {
      // START:: TABS DATA
      tabsContent: [
        {
          tab: this.$t("profile.myData.title"),
          key_name: "myData",
        },
        {
          tab: this.$t("profile.rating"),
          key_name: "rating",
        },
      ],
      // END:: TABS DATA

      // START:: MY DATA
      myData: null,
      // End:: MY DATA

      // START:: REVIEWS
      reviews: null,
      // End:: REVIEWS

      // START:: Loading
      loading: false,
      // End:: Loading
    };
  },
  methods: {
    // START:: Get My Data
    getData() {
      this.loading = true;
      this.$axios.get("/profile").then((res) => {
        this.loading = false;
        this.myData = res.data.data;
        this.reviews = res.data.reviews;
      });
    },
    // END:: Get My Data
  },
  components: {
    MyData,
    RatingBox,
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
