<template>
  <div class="edit_data pad_50 all_bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="main_title with_margin">
          <h3>{{ $t("profile.editData.title") }}</h3>
        </div>
        <div class="col-md-6">
          <!--Start Inputs Part-->
          <div class="auth_inputs mb-3">
            <form>
              <!-- Start Image Upload -->
              <uplode-image
                :data_src="data.avatar"
                class="mb-3"
                @inputChanged="uplodeImg_1"
              ></uplode-image>
              <!-- End Image Upload -->
              <!--Start Frist Name-->
              <div class="input_wrapper">
                <input
                  class="form-control"
                  type="text"
                  v-model="data.firstName"
                  :placeholder="$t('auth.firstName')"
                />
              </div>
              <!--End First Number-->
              <!--Start Last Name-->
              <div class="input_wrapper">
                <input
                  class="form-control"
                  type="text"
                  v-model="data.lastName"
                  :placeholder="$t('auth.lastName')"
                />
              </div>
              <!--End Last Name-->
              <div class="wrapper_flex">
                <!--Start Phone Number-->
                <div class="input_wrapper phone_num">
                  <input
                    class="form-control"
                    type="number"
                    v-model="data.phone"
                    :placeholder="$t('auth.phone')"
                  />
                </div>
                <!--End Phone Number-->
              </div>
              <!--Start Email-->
              <div class="input_wrapper">
                <input
                  class="form-control"
                  type="email"
                  v-model="data.email"
                  :placeholder="$t('auth.email')"
                />
              </div>
              <!--End Email-->

              <!--Start Country-->
              <div class="input_wrapper phone_code select">
                <select
                  class="form-control"
                  v-model="data.country"
                  @change="getCities"
                >
                  <option value="">{{ $t("auth.country") }}</option>
                  <option
                    v-for="country in countries"
                    :key="country.id"
                    :value="country.id"
                  >
                    {{ country.name }}
                  </option>
                </select>
              </div>
              <!--End Country-->

              <!--Start City-->
              <div class="input_wrapper phone_code select">
                <select class="form-control" v-model="data.city">
                  <option value="">{{ $t("auth.city") }}</option>
                  <option
                    v-for="city in cities"
                    :key="city.id"
                    :value="city.id"
                  >
                    {{ city.name }}
                  </option>
                </select>
              </div>
              <!--End City-->
              <!--Start Address-->
              <div class="input_wrapper">
                <input
                  class="form-control"
                  type="text"
                  v-model="data.address"
                  :placeholder="$t('auth.address')"
                />
              </div>
              <!--End Address-->
              <!--Edit Button-->
              <button
                type="button"
                class="main_button button mt-3"
                ref="edit"
                @click="editData"
              >
                <i class="fas fa-spinner"></i>
                {{ $t("edit") }}
              </button>
              <!--Edit Button-->
            </form>
          </div>
          <!--End Inputs Part-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // START:: EDIT DATA
      data: {
        avatar: null,
        firstName: null,
        lastName: null,
        phoneCode: null,
        phone: null,
        email: null,
        country: "",
        city: "",
        address: "",
      },
      // END:: EDIT DATA

      // Start:: GET COUNTRIES
      countries: [],
      // END:: GET COUNTRIES

      // Start:: GET CITIES
      cities: [],
      // END:: GET CITIES
    };
  },
  methods: {
    // START:: Uplode Avatar
    uplodeImg_1(obj) {
      this.data.avatar = obj;
    },
    // END:: Uplode Avatar

    // START:: Get Countries
    getCountries() {
      this.$axios.get("countries").then((res) => {
        this.countries = res.data.data;
      });
    },
    // END:: Get Countries

    // START:: Get Cities
    getCities() {
      this.$axios.get("cities?country_id=" + this.data.country).then((res) => {
        this.cities = res.data.data;
      });
    },
    // END:: Get Cities

    // START:: Get My Data
    getData() {
      this.$axios.get("/profile").then((res) => {
        let allData = res.data.data;
        this.data.avatar = allData.image;
        this.data.firstName = allData.first_name;
        this.data.lastName = allData.last_name;
        this.data.email = allData.email;
        this.data.phone = allData.phone;
        this.data.country = allData.country.id;
        this.data.city = allData.city.id;
        this.data.address = allData.address;
        this.getCities();
      });
    },
    // END:: Get My Data

    // Start Edit Data
    editData() {
      const submitButton = this.$refs["edit"];
      submitButton.classList.add("click");
      if (this.data.firstName == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.firstName"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.lastName == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.lastName"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.phone == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.phone"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.email == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.email"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.country == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.country"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.city == "null") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.city"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.address == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.address"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        const data = new FormData();
        if (this.data.avatar.img_file) {
          data.append("image", this.data.avatar.img_file);
        }
        data.append("first_name", this.data.firstName);
        data.append("last_name", this.data.lastName);
        data.append("email", this.data.email);
        data.append("phone", this.data.phone);
        data.append("country_id", this.data.country);
        data.append("city_id", this.data.city);
        data.append("address", this.data.address);
        this.$axios
          .post("/profile", data)
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$router.push({ path: "/profile" });
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("editSuccess"),
                position: "bottomRight",
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
    //End Edit Data
  },
  mounted() {
    this.getCountries();
    this.getData();
  },
};
</script>

<style></style>
