<template>
  <div class="login most_bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <!--Start Auth Page-->
          <div class="auth_form auth_center">
            <!--Start Top Part-->
            <div class="auth_top">
              <div class="logo">
                <router-link to="/"
                  ><img src="@/assets/images/Icons/logo.png"
                /></router-link>
              </div>
              <div class="title_content">
                <h4>{{ $t("forgetPassword.title") }}</h4>
                <p>{{ $t("forgetPassword.desc") }}</p>
              </div>
            </div>
            <!--End Top Part-->
            <!--Start Inputs Part-->
            <div class="auth_inputs">
              <form @submit.prevent="forgetPass">
                <div class="wrapper_flex">
                  <!--Start Phone Number-->
                  <div class="input_wrapper phone_num">
                    <input
                      class="form-control"
                      type="number"
                      v-model="data.phone"
                      :placeholder="$t('auth.phone')"
                    />
                  </div>
                  <!--Start Phone Number-->
                </div>
                <!--Login Button-->
                <button class="main_button button" ref="send">
                  <i class="fas fa-spinner"></i>
                  {{ $t("sendBtn") }}
                </button>
                <!--Login Button-->
              </form>
            </div>
            <!--End Inputs Part-->
            <!--Start Have Account-->
            <!-- <div class="have_account">
              <span class="red_text">{{ $t("login.haveAccount") }}</span>
              <router-link to="/signUp" class="second_button">{{
                $t("login.createAccount")
              }}</router-link>
            </div> -->
            <!--End Have Account-->
          </div>
          <!--End Auth Page-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //START INPUTS DATA
      data: {
        phone: null,
      },
      //END INPUTS DATA

      //START CODE DATA
      code: null,
      //END CODE DATA
    };
  },
  methods: {
    // Start Login
    forgetPass() {
      const submitButton = this.$refs["send"];
      submitButton.classList.add("click");
      if (this.data.phone == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.phone"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        const data = new FormData();
        data.append("phone", this.data.phone);
        this.$store.dispatch("auth_module/phone", this.data);
        this.$axios
          .post("/send_code", data)
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$router.push({ path: "/checkCode" });
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("sendSuccess"),
                position: "bottomRight",
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
    //End Login
  },
};
</script>
