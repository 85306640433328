<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="foot">
            <h4>{{ $t("footer.importantLinks") }}</h4>
            <ul>
              <li>
                <router-link to="/siteCommission">{{
                  $t("footer.commission")
                }}</router-link>
              </li>
              <li>
                <router-link to="/faqs">{{ $t("footer.faqs") }}</router-link>
              </li>
              <li>
                <router-link to="/terms">{{ $t("footer.terms") }}</router-link>
              </li>
              <li>
                <router-link to="/usagePolicy">{{
                  $t("footer.policy")
                }}</router-link>
              </li>
              <li>
                <router-link to="/contactUs">{{
                  $t("footer.contact")
                }}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="foot">
            <div class="logo">
              <img src="@/assets/images/Icons/logo.png" />
            </div>
            <div class="desc">
              <p v-if="allData">
                {{ allData.about }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="foot">
            <h4>{{ $t("footer.downloadClient") }}</h4>
            <ul class="download_app">
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.alamyia.manfaa_client"
                  ><img src="@/assets/images/Icons/google.png"
                /></a>
              </li>
              <li>
                <a
                  href="https://apps.apple.com/us/app/%D9%85%D9%86%D9%81%D8%B9%D8%A9/id1610112036"
                  ><img src="@/assets/images/Icons/apple.png"
                /></a>
              </li>
            </ul>
          </div>
          <div class="foot">
            <h4>{{ $t("footer.downloadDriver") }}</h4>
            <ul class="download_app">
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.alalmiyalhura.manfaa_driver"
                  ><img src="@/assets/images/Icons/google.png"
                /></a>
              </li>
              <li>
                <a
                  href="https://apps.apple.com/us/app/%D9%85%D9%86%D9%81%D8%B9%D8%A9-%D9%85%D9%86%D8%AF%D9%88%D8%A8/id1610320578"
                  ><img src="@/assets/images/Icons/apple.png"
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      //Start Get Data
      allData: null,
      //End Get Data
      //Start Loading
      loading: false,
      //End Loading
    };
  },
  methods: {
    //Get Data
    GetData() {
      this.loading = true;
      this.$axios.get("about").then((res) => {
        this.allData = res.data.data;
        this.loading = false;
      });
    },
    //Get Data
  },
  mounted() {
    this.GetData();
  },
};
</script>

<style lang="scss" scoped></style>
