<template>
  <div class="profile_rating">
    <div class="rating_box" v-for="item in reviews" :key="item.id">
      <!--Image-->
      <div class="img">
        <img :src="item.user.image" />
      </div>
      <!--Image-->
      <!--Details-->
      <div class="exp">
        <div class="top_exp">
          <span class="name"
            >{{ item.user.first_name }} {{ item.user.last_name }}</span
          >
          <Rate :rate_num="Math.floor(item.rate)"></Rate>
        </div>
        <p>
          {{ item.comment }}
        </p>
      </div>
      <!--Details-->
    </div>
  </div>
</template>

<script>
export default {
  props: ["reviews"],
  data() {
    return {
      //START Reviews DATA
      // reviews: null,
      //END Reviews DATA

      //Start Loading
      loading: false,
      //END Loading
    };
  },
  methods: {
    //Start Get Reviews
    getReviews() {
      this.loading = true;
      this.$axios.get("reviews?provider_id=" + this.userId).then((res) => {
        this.reviews = res.data.data;
        this.loading = false;
      });
    },
    //End Get Reviews
  },
  computed: {
    userId() {
      return this.$store.getters["auth_module/set_userId"];
    },
  },
  mounted() {
    this.getReviews();
  },
};
</script>

<style></style>
