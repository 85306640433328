<template>
  <div class="home_products">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="head_part">
            <div class="main_title">
              <h3>{{ $t("products.mainTitle") }}</h3>
            </div>
            <!-- Go Last Offers-->
            <!-- <div class="more_items">
              <router-link to="/lastOffers"
                >{{ $t("products.moreItems") }}
                <span class="icon"><i class="fas fa-long-arrow-left"></i></span
              ></router-link>
            </div> -->
            <!-- Go Last Offers-->
          </div>
        </div>
        <!-- Home Products-->
        <div class="col-sm-12">
          <!--Start Tab Text-->
          <ul class="nav nav-pills" id="pills-tab2" role="tablist">
            <li
              class="nav-item"
              v-for="category in categories"
              :key="category.id"
            >
              <button
                type="button"
                class="nav-link"
                :class="{ active: tabText === 'content' + category.id }"
                @click="
                  tabClick('content' + category.id),
                    getSubCategories(category.id, 0),
                    getAllProducts(category.id)
                "
              >
                <img :src="category.image" />
                {{ category.name }}
              </button>
            </li>
          </ul>
          <!--End Tab Text-->

          <!--Start Tab Content-->
          <div class="tab-content">
            <transition name="fadeIn">
              <template v-for="cat in categories">
                <!--Main Categories-->
                <div
                  v-if="tabText === 'content' + cat.id"
                  class="fadeIn"
                  :key="cat.id"
                >
                  <!--Start Tab Text Sub-->
                  <!-- <pre dir="ltr">{{ subCatTree }}</pre> -->
                  <ul
                    class="nav nav-pills second"
                    role="tablist"
                    v-if="subCatTree1.length > 0"
                  >
                    <!--All-->
                    <!-- <li class="nav-item">
                      <button
                        type="button"
                        class="nav-link"
                        :class="{ active: tabText2 === 'all' }"
                        @click="tabClick2('all'), getAllProducts(cat.id)"
                      >
                        {{ $t("all") }}
                      </button>
                    </li> -->
                    <!--All -->
                    <!--Subs-->
                    <li
                      class="nav-item"
                      v-for="sub in subCatTree1"
                      :key="sub.id"
                    >
                      <!-- <pre dir="ltr">SUBCAT:: {{ sub }}</pre> -->

                      <!-- All -->
                      <button
                        type="button"
                        class="nav-link"
                        :class="{ active: tabText2 === 'subcat' + sub.id }"
                        @click="
                          tabClick2('subcat' + sub.id),
                            getProducts(cat.id, sub.id),
                            getSubCategories(sub.id, 1)
                        "
                      >
                        {{ sub.name }}
                      </button>
                      <!-- All -->
                    </li>
                    <!--Subs-->
                  </ul>

                  <ul
                    class="nav nav-pills second"
                    role="tablist"
                    v-if="subCatTree2.length > 0"
                  >
                    <!--All-->
                    <!-- <li class="nav-item">
                      <button
                        type="button"
                        class="nav-link"
                        :class="{ active: tabText2 === 'all' }"
                        @click="tabClick2('all'), getAllProducts(cat.id)"
                      >
                        {{ $t("all") }}
                      </button>
                    </li> -->
                    <!--All -->

                    <!--Subs-->
                    <li
                      class="nav-item"
                      v-for="sub in subCatTree2"
                      :key="sub.id"
                    >
                      <!-- <pre dir="ltr">SUBCAT:: {{ sub }}</pre> -->

                      <!-- All -->
                      <button
                        type="button"
                        class="nav-link"
                        :class="{ active: tabText2 === 'subcat' + sub.id }"
                        @click="
                          tabClick2('subcat' + sub.id),
                            getProducts(cat.id, sub.id),
                            getSubCategories(sub.id, 2)
                        "
                      >
                        {{ sub.name }}
                      </button>
                      <!-- All -->
                    </li>
                    <!--Subs-->
                  </ul>

                  <ul
                    class="nav nav-pills second"
                    role="tablist"
                    v-if="subCatTree3.length > 0"
                  >
                    <!--All-->
                    <!-- <li class="nav-item">
                      <button
                        type="button"
                        class="nav-link"
                        :class="{ active: tabText2 === 'all' }"
                        @click="tabClick2('all'), getAllProducts(cat.id)"
                      >
                        {{ $t("all") }}
                      </button>
                    </li> -->
                    <!--All -->

                    <!--Subs-->
                    <li
                      class="nav-item"
                      v-for="sub in subCatTree3"
                      :key="sub.id"
                    >
                      <!-- <pre dir="ltr">SUBCAT:: {{ sub }}</pre> -->

                      <!-- All -->
                      <button
                        type="button"
                        class="nav-link"
                        :class="{ active: tabText2 === 'subcat' + sub.id }"
                        @click="
                          tabClick2('subcat' + sub.id),
                            getProducts(cat.id, sub.id),
                            getSubCategories(sub.id, 3)
                        "
                      >
                        {{ sub.name }}
                      </button>
                      <!-- All -->
                    </li>
                    <!--Subs-->
                  </ul>

                  <ul
                    class="nav nav-pills second"
                    role="tablist"
                    v-if="subCatTree4.length > 0"
                  >
                    <!--All-->
                    <!-- <li class="nav-item">
                      <button
                        type="button"
                        class="nav-link"
                        :class="{ active: tabText2 === 'all' }"
                        @click="tabClick2('all'), getAllProducts(cat.id)"
                      >
                        {{ $t("all") }}
                      </button>
                    </li> -->
                    <!--All -->

                    <!--Subs-->
                    <li
                      class="nav-item"
                      v-for="sub in subCatTree4"
                      :key="sub.id"
                    >
                      <!-- <pre dir="ltr">SUBCAT:: {{ sub }}</pre> -->

                      <!-- All -->
                      <button
                        type="button"
                        class="nav-link"
                        :class="{ active: tabText2 === 'subcat' + sub.id }"
                        @click="
                          tabClick2('subcat' + sub.id),
                            getProducts(cat.id, sub.id),
                            getSubCategories(sub.id, 4)
                        "
                      >
                        {{ sub.name }}
                      </button>
                      <!-- All -->
                    </li>
                    <!--Subs-->
                  </ul>
                  <!--End Tab Text Sub-->

                  <!--Start Tab Content2-->
                  <div class="tab-content">
                    <transition name="fadeIn">
                      <product-loader v-if="loading"></product-loader>
                      <div class="row" v-else>
                        <no-data v-if="products.length == 0"></no-data>
                        <template v-else>
                          <div
                            class="col-md-3"
                            v-for="product in products"
                            :key="product.id"
                          >
                            <ProductCard :productItem="product"></ProductCard>
                          </div>
                          <div class="pagenation" v-if="last_page > 1">
                            <ul>
                              <li
                                v-for="index in last_page"
                                :key="index"
                                v-if="
                                  Math.abs(currentPage - index) < 4 ||
                                  index == last_page ||
                                  index == 0
                                "
                                :class="{
                                  last:
                                    index == last_page &&
                                    Math.abs(currentPage - index) > 4,
                                }"
                              >
                                <button
                                  type="button"
                                  @click="
                                    indexNumber(index),
                                      getProducts(cat.id, subId)
                                  "
                                  :class="{
                                    active: index == currentPage,
                                    last:
                                      last_page == index &&
                                      Math.abs(currentPage - index) > 4,
                                  }"
                                >
                                  {{ index }}
                                </button>
                              </li>
                              <!-- <li
                                v-for="index in last_page"
                                :class="index === currentPage ? 'active' : ''"
                                :key="index"
                                @click="
                                  indexNumber(index), getProducts(cat.id, subId)
                                "
                              >
                                {{ index }}
                              </li> -->
                            </ul>
                          </div>
                        </template>
                      </div>
                      <!-- <div v-if="tabText2 === 'all'" class="fadeIn">
                        <product-loader v-if="loading"></product-loader>
                        <div class="row" v-else>
                          <no-data v-if="products.length == 0"></no-data>
                          <template v-else>
                            <div
                              class="col-md-3"
                              v-for="product in products"
                              :key="product.id"
                            >
                              <ProductCard :productItem="product"></ProductCard>
                            </div>
                            <div class="pagenation" v-if="last_page > 1">
                              <ul>
                                <li
                                  v-for="index in last_page"
                                  :class="index === currentPage ? 'active' : ''"
                                  :key="index"
                                  @click="
                                    indexNumber(index), getAllProducts(cat.id)
                                  "
                                >
                                  {{ index }}
                                </li>
                              </ul>
                            </div>
                          </template>
                        </div>
                      </div>
                      <template v-for="pro in cat.subcategories">
                        <div
                          v-if="tabText2 === 'subcat' + pro.id"
                          class="fadeIn"
                          :key="pro.id"
                        >
                          <product-loader v-if="loading"></product-loader>
                          <div class="row" v-else>
                            <no-data v-if="products.length == 0"></no-data>
                            <template v-else>
                              <div
                                class="col-md-3"
                                v-for="product in products"
                                :key="product.id"
                              >
                                <ProductCard
                                  :productItem="product"
                                ></ProductCard>
                              </div>
                              <div class="pagenation" v-if="last_page > 1">
                                <ul>
                                  <li
                                    v-for="index in last_page"
                                    :class="
                                      index === currentPage ? 'active' : ''
                                    "
                                    :key="index"
                                    @click="
                                      indexNumber(index),
                                        getProducts(cat.id, pro.id)
                                    "
                                  >
                                    {{ index }}
                                  </li>
                                </ul>
                              </div>
                            </template>
                          </div>
                        </div>
                      </template> -->
                    </transition>
                  </div>
                  <!--End Tab Content2-->
                </div>
                <!--Main Categories-->
              </template>
            </transition>
          </div>
          <!--End Tab Content-->
        </div>
        <!-- Home Products-->
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "./ProductCard.vue";

export default {
  data() {
    return {
      //Start:: Categories
      categories: [],
      //End:: Categories

      //Start:: SubCategories
      subCategories: [],
      subCatTree1: [],
      subCatTree2: [],
      subCatTree3: [],
      subCatTree4: [],
      subCatTree5: [],
      //End:: SubCategories
      subId: null,
      // Start:: Products
      products: [],
      // END:: Products

      // START:: TAB TEXT
      tabText: "",
      // END:: TAB TEXT

      // START:: TAB TEXT
      tabText2: "all",
      // END:: TAB TEXT

      // START:: LOADING
      loading: false,
      // END:: LOADING

      // ========== pagination
      paginations: {
        current_page: 1,
        last_page: 8,
      },
      pageNumber: 1,

      // START :: PAGENATION
      last_page: "",
      currentPage: 1,
      pageNum: "",
      // END :: PAGENATION
    };
  },

  watch: {
    "paginations.current_page"(newVal) {
      this.pageNumber = newVal;
      this.getAllProducts();
    },
  },

  methods: {
    //Tab Click
    tabClick(val) {
      this.tabText = val;
    },
    //Tab Click

    //Tab Click 2
    tabClick2(val) {
      this.tabText2 = val;
    },
    //Tab Click 2

    // pagenation
    indexNumber(pageNumber) {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      if (this.currentPage !== pageNumber) {
        this.currentPage = pageNumber;
      }
    },
    // pagenation

    //Get Categories
    getCategories() {
      this.$axios.get("/categories").then((res) => {
        this.categories = res.data.data;
        this.tabText = "content" + this.categories[0].id;
        this.getSubCategories(this.categories[0].id, 0);
        this.getAllProducts(this.categories[0].id);
        setTimeout(() => {}, 400);
      });
    },
    //Get Categories

    //Get Subcategories
    getSubCategories(id, subLevel) {
      // this.subCatTree = [];
      this.$axios.get("children_category?category_id=" + id).then((res) => {
        if (subLevel == 0) {
          this.subCatTree1 = res.data.data;
          this.subId = id;
          this.currentPage = 1;
          this.subCatTree2 = [];
          this.subCatTree3 = [];
          this.subCatTree4 = [];
          this.subCatTree5 = [];
        } else if (subLevel == 1) {
          this.subCatTree2 = res.data.data;
          this.subId = id;
          this.currentPage = 1;
          this.subCatTree3 = [];
          this.subCatTree4 = [];
          this.subCatTree5 = [];
        } else if (subLevel == 2) {
          this.subCatTree3 = res.data.data;
          this.subId = id;
          this.currentPage = 1;
          this.subCatTree4 = [];
          this.subCatTree5 = [];
        } else if (subLevel == 3) {
          this.subCatTree4 = res.data.data;
          this.subId = id;
          this.currentPage = 1;
          this.subCatTree5 = [];
        } else if (subLevel == 4) {
          this.subCatTree5 = res.data.data;
          this.subId = id;
          this.currentPage = 1;
          this.subCatTree5 = [];
        }
      });
    },

    //old
    // getSubCategories(id) {
    //   this.subCatTree = [];
    //   this.$axios.get("children_tree?category_id=" + id).then((res) => {
    //     this.subCatTree.push(res.data.data.subcategory);
    //   });
    // },
    //Get Subcategories

    // ====== Get Products
    // All
    getAllProducts(id) {
      this.loading = true;

      this.$axios
        .get(
          `products?category_id=${id ? id : this.categories[0].id}&page=${
            this.currentPage
          }`
        )
        .then((res) => {
          this.products = res.data.data;

          // ==== Pagination
          this.paginations.last_page = res.data.meta.last_page;
          this.last_page = res.data.meta.last_page;
          this.pageNum = res.data.meta.to;

          // console.log("LAAAST: ", this.pageNumber);
          // console.log(this.paginations.last_page);

          setTimeout(() => {
            this.loading = false;
          }, 100);
        });
    },

    // Sub
    // handelSubCatsLevels(nextLevelSubcats, subCatLevel) {
    //   if (this.subCatTree[subCatLevel++]) {
    //     this.subCatTree.splice(subCatLevel++, this.subCatTree.length);
    //   }
    //   this.subCatTree.push(nextLevelSubcats);
    // },

    getProducts(id, sub_id) {
      this.loading = true;

      this.$axios
        .get(
          "products?category_id=" +
            id +
            "&subcategory_id=" +
            sub_id +
            "&page=" +
            this.currentPage
        )
        .then((res) => {
          this.products = res.data.data;
          this.loading = false;
          this.last_page = res.data.meta.last_page;
          this.pageNum = res.data.meta.to;
        });
    },
    //Get Products
  },

  components: {
    ProductCard,
  },

  created() {
    this.getCategories();
  },
};
</script>
