<template>
  <div class="not_found Pad_50">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="not_content">
            <img src="@/assets/images/Icons/not-found.svg" />
            <h4>{{ $t("notFoundData") }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.not_found {
  .not_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    img {
      width: 400px;
      margin-bottom: 20px;
    }
    h4 {
      font-weight: 600;
      color: #354128;
      margin-bottom: 30px;
      font-size: 25px;
    }
  }
}
</style>
