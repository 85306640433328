<template>
  <div class="last_offers all_bg pad_50">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="last_offer_head">
            <div class="filter">
              <button class="btn" @click="showModel">
                <img src="@/assets/images/Icons/filter.png" />
                <span>{{ $t("filter.title") }}</span>
              </button>
            </div>
            <div class="main_title">
              <h3>{{ tabsContent.name }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <!--Start Tab Text-->
          <ul class="nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item">
              <button
                type="button"
                class="nav-link"
                :class="{ active: tabText === 'all' }"
                @click="tabClick('all'), getAllProducts()"
              >
                {{ $t("all") }}
              </button>
            </li>
            <li class="nav-item" v-for="sub in subCategories" :key="sub.id">
              <button
                type="button"
                class="nav-link"
                :class="{ active: tabText === 'content' + sub.id }"
                @click="tabClick('content' + sub.id), getProducts(sub.id)"
              >
                {{ sub.name }}
              </button>
            </li>
          </ul>
          <!--End Tab Text-->

          <!--Start Tab Content-->
          <div class="tab-content">
            <transition name="fadeIn">
              <div v-if="tabText === 'all'" class="fadeIn">
                <div class="text fadeIn">
                  <!--Product Card-->
                  <product-loader v-if="loading"></product-loader>
                  <div class="row" v-else>
                    <no-data v-if="products.length == 0"></no-data>
                    <template v-else>
                      <div
                        class="col-md-3"
                        v-for="product in products"
                        :key="product.id"
                      >
                        <ProductCard :productItem="product"></ProductCard>
                      </div>
                      <!-- ========== Pagination ========== -->
                      <div class="pagenation" v-if="last_page > 1">
                        <ul>
                          <li
                            v-for="index in last_page"
                            :class="index === currentPage ? 'active' : ''"
                            :key="index"
                            @click="indexNumber(index), getAllProducts()"
                          >
                            {{ index }}
                          </li>
                        </ul>
                      </div>
                    </template>
                  </div>
                  <!--Product Card-->
                </div>
              </div>
              <template v-for="sub2 in subCategories">
                <div
                  v-if="tabText === 'content' + sub2.id"
                  class="fadeIn"
                  :key="sub2.id"
                >
                  <div class="text fadeIn">
                    <!--Product Card-->
                    <product-loader v-if="loading"></product-loader>
                    <div class="row" v-else>
                      <no-data v-if="products.length == 0"></no-data>
                      <template v-else>
                        <div
                          class="col-md-3"
                          v-for="product in products"
                          :key="product.id"
                        >
                          <ProductCard :productItem="product"></ProductCard>
                        </div>
                        <!-- ========== Pagination ========== -->
                        <div class="pagenation" v-if="last_page > 1">
                          <ul>
                            <li
                              v-for="index in last_page"
                              :class="index === currentPage ? 'active' : ''"
                              :key="index"
                              @click="indexNumber(index), getProducts(sub2.id)"
                            >
                              {{ index }}
                            </li>
                          </ul>
                        </div>
                      </template>
                    </div>
                    <!--Product Card-->
                  </div>
                </div>
              </template>
            </transition>
          </div>
          <!--End Tab Content-->
        </div>
      </div>
    </div>
    <!--Filter-->
    <base-model :show="filter.show" @closeModel="closeModel">
      <div class="filter_model">
        <h4>{{ $t("filter.title") }}</h4>
        <p>{{ $t("filter.reportReason") }}</p>
        <div class="row">
          <div class="col-sm-12">
            <form>
              <!--Filter Country-->
              <div class="input_wrapper round_radius select">
                <select
                  class="form-control"
                  v-model="filter.country"
                  @change="getCities"
                >
                  <option value="">
                    {{ $t("advertisment.add.country") }}
                  </option>
                  <option
                    :value="country.id"
                    v-for="country in countries"
                    :key="country.id"
                  >
                    {{ country.name }}
                  </option>
                </select>
              </div>
              <!--Filter Country-->
              <!--Filter City-->
              <div class="input_wrapper round_radius select">
                <select class="form-control" v-model="filter.city">
                  <option disabled value="">
                    {{ $t("advertisment.add.city") }}
                  </option>
                  <option
                    v-for="item in cities"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <!--Filter City-->
              <!--Filter Category-->
              <div class="input_wrapper round_radius select">
                <select class="form-control" v-model="filter.category">
                  <option value="">
                    {{ $t("advertisment.add.category") }}
                  </option>
                  <option
                    :value="category.id"
                    v-for="category in categories"
                    :key="category.id"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
              <!--Filter Category-->
              <!--Range Slider-->
              <div class="price-slider">
                <input
                  min="1"
                  max="5000"
                  step="10"
                  type="range"
                  v-model="filter.priceFrom"
                />
                <input
                  min="1"
                  max="5000"
                  step="10"
                  type="range"
                  v-model="filter.priceTo"
                />
              </div>
              <div class="price-range">
                <span class="min"
                  ><span class="currency"></span> {{ filter.priceFrom }}</span
                >
                <span class="max"
                  ><span class="currency"></span> {{ filter.priceTo }}</span
                >
              </div>
              <!--Range Slider-->
              <!--filter btn-->
              <button class="filter_btn" type="button" @click="filterData">
                {{ $t("filter.title") }}
              </button>
              <!--filter btn-->
            </form>
          </div>
        </div>
      </div>
    </base-model>
    <!--Filter-->
  </div>
</template>

<script>
import ProductCard from "@/components/HomePage/Products/ProductCard.vue";
export default {
  props: ["id"],
  data() {
    return {
      // Start:: Filter Data
      filter: {
        show: false,
        country: "",
        city: "",
        category: "",
        priceFrom: 0,
        priceTo: 5000,
      },
      // End:: Filter Data
      // START:: TABS DATA
      tabsContent: [
        {
          tab: this.$t("products.subTabs.all"),
          key_name: "all",
        },
        {
          tab: this.$t("products.subTabs.cars"),
          key_name: "cars",
        },
        {
          tab: this.$t("products.subTabs.building"),
          key_name: "building",
        },
        {
          tab: this.$t("products.subTabs.phones"),
          key_name: "phones",
        },
      ],
      // END:: TABS DATA

      // START:: PRODUCTS
      products: [],
      // END:: PRODUCTS

      // START:: CATEGORIES
      categories: [],
      // END:: CATEGORIES

      // START:: COUNTRIES
      countries: [],
      // END:: COUNTRIES

      // START:: CITIES
      cities: [],
      // END:: CITIES

      // START:: SUBCATEGORIES
      subCategories: [],
      // END:: SUBCATEGORIES

      // START:: FILTER PRODUCT
      filterProduct: [],
      // END:: FILTER PRODUCT

      // START:: TAB TEXT
      tabText: "all",
      // END:: TAB TEXT

      // START:: LOADING
      loading: false,
      // END:: LOADING
      // START :: PAGENATION
      last_page: "",
      currentPage: 1,
      pageNum: "",
      // END :: PAGENATION
    };
  },
  methods: {
    //Show Model
    showModel() {
      this.filter.show = true;
    },
    //Show Model
    //Hide Model
    closeModel() {
      this.filter.show = false;
    },
    //Hide Model

    //Tab Click
    tabClick(val) {
      this.tabText = val;
    },
    //Tab Click

    // pagenation
    indexNumber(pageNumber) {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      if (this.currentPage !== pageNumber) {
        this.currentPage = pageNumber;
      }
    },
    // pagenation

    // ==========> GetData
    GetData() {
      // Categories
      this.$axios.get("/categories").then((res) => {
        this.categories = res.data.data;
      });

      // Counrties
      this.$axios.get("/countries").then((res) => {
        this.countries = res.data.data;
      });
    },

    //Get Cities
    getCities() {
      this.$axios
        .get(`cities?country_id=${this.filter.country}`)
        .then((res) => {
          this.cities = res.data.data;
        });
    },
    //Get Cities

    //Get Subcategories
    getSubCategories() {
      this.$axios.get("subcategories?category_id=" + this.id).then((res) => {
        this.subCategories = res.data.data;
      });
    },
    //Get Subcategories

    //Get Products
    //All
    getAllProducts() {
      this.loading = true;
      this.$axios
        .get("products?category_id=" + this.id + "&page=" + this.currentPage)
        .then((res) => {
          this.products = res.data.data;
          this.loading = false;
          this.last_page = res.data.meta.last_page;
          this.pageNum = res.data.meta.to;
        });
    },
    //Sub
    getProducts(id) {
      this.loading = true;
      this.$axios
        .get(
          "products?category_id=" +
            this.id +
            "&subcategory_id=" +
            id +
            "&page=" +
            this.currentPage
        )
        .then((res) => {
          this.products = res.data.data;
          this.loading = false;
          this.last_page = res.data.meta.last_page;
          this.pageNum = res.data.meta.to;
        });
    },

    //Filter
    filterData() {
      console.log("origin: ", this.filter);
      this.$store.commit("filter_module/set_filter", this.filter);
      this.$router.push({ path: "/filter" });
      this.closeModel();
    },
    //Get Products
  },
  components: {
    ProductCard,
  },
  mounted() {
    this.GetData();
    this.getSubCategories();
    this.getAllProducts();
  },
};
</script>

<style></style>
