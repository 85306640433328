<template>
  <div>
    <TopHeader></TopHeader>
    <MainHeader></MainHeader>
    <transition name="slither" mode="out-in">
      <router-view :key="$route.path"></router-view>
    </transition>
    <Footer></Footer>
  </div>
</template>

<script>
//Top Header
import TopHeader from "@/components/Layouts/TopHeader.vue";
//Main Header
import MainHeader from "@/components/Layouts/TheHeader.vue";
//Footer
import Footer from "@/components/Layouts/TheFooter.vue";
export default {
  name: "Home",
  components: {
    TopHeader,
    MainHeader,
    Footer,
  },
};
</script>
