<template>
  <div class="last_offers all_bg pad_50">
    <div class="container">
      <product-loader v-if="loading"></product-loader>
      <div class="row" v-else>
        <no-data v-if="products.length == 0"></no-data>
        <template v-else>
          <div class="col-md-3" v-for="product in products" :key="product.id">
            <ProductCard :productItem="product"></ProductCard>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/HomePage/Products/ProductCard.vue";
export default {
  props: ["text"],
  data() {
    return {
      //Start Products
      products: [],
      //End Products
      //Start Loading
      loading: false,
      //End Loading
    };
  },
  methods: {
    //Get Products
    getProducts() {
      this.loading = true;
      this.$axios.get("products?keyword=" + this.text).then((res) => {
        this.products = res.data.data;
        this.loading = false;
      });
    },
    //Get Products
  },
  components: {
    ProductCard,
  },
  computed: {},
  mounted() {
    this.getProducts();
  },
};
</script>

<style></style>
