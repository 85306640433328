<template>
  <div class="top_header">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="date_time">
            <div class="clock">
              <span class="icon"><i class="fal fa-clock"></i></span>
              <ul>
                <li id="min" class="bold_sm">
                  <span v-if="minutes < 10">0</span>{{ minutes }}
                </li>
                <li id="point" class="bold_sm">:</li>
                <li id="hours" class="bold_sm">{{ hours }}</li>
              </ul>
            </div>
            <div class="date">
              <span class="icon"><i class="fal fa-calendar-alt"></i></span>
              <div id="Date" class="bold_sm">{{ date }}</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="lang">
            <button class="btn" @click="changeLang">
              <img v-if="lang == 'ar'" src="@/assets/images/Icons/en.png" />
              <img v-if="lang == 'en'" src="@/assets/images/Icons/ar.png" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //DATE
      date: null,
      hours: null,
      minutes: null,
    };
  },
  methods: {
    getNow() {
      const today = new Date();
      const date =
        today.getFullYear() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getDate();
      this.date = date;
      this.hours = today.getHours();
      this.minutes = today.getMinutes();
    },
    changeLang() {
      this.$store.dispatch("lang_module/switchLang");
    },
  },
  mounted() {
    setInterval(() => {
      this.getNow();
    }, 300);
  },
  computed: {
    lang() {
      return this.$store.getters["lang_module/lang"];
    },
  },
};
</script>

<style></style>
