<template>
  <div class="last_offers all_bg pad_50">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="last_offer_head">
            <div class="filter">
              <button class="btn" @click="showModel">
                <img src="@/assets/images/Icons/filter.png" />
                <span>فلتر</span>
              </button>
            </div>
            <div class="main_title">
              <h3>{{ $t("lastOffers.title") }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3" v-for="product in products" :key="product.id">
          <ProductCard :productItem="product"></ProductCard>
        </div>
      </div>
    </div>
    <!--Filter-->
    <base-model :show="filter.show" @closeModel="closeModel">
      <div class="filter_model">
        <h4>{{ $t("filter.title") }}</h4>
        <p>{{ $t("filter.reportReason") }}</p>
        <div class="row">
          <div class="col-sm-12">
            <form>
              <!--Filter City-->
              <div class="input_wrapper round_radius select">
                <select class="form-control" v-model="filter.city">
                  <option value="">المدينة</option>
                  <option value="">المدينة</option>
                  <option value="">المدينة</option>
                </select>
              </div>
              <!--Filter City-->
              <!--Filter Category-->
              <div class="input_wrapper round_radius select">
                <select class="form-control" v-model="filter.category">
                  <option value="">القسم</option>
                  <option value="">القسم</option>
                  <option value="">القسم</option>
                </select>
              </div>
              <!--Filter Category-->
              <!--Range Slider-->
              <div class="price-slider">
                <input
                  min="1"
                  max="50000"
                  step="10"
                  type="range"
                  v-model="filter.min"
                />
                <input
                  min="1"
                  max="5000"
                  step="10"
                  type="range"
                  v-model="filter.max"
                />
              </div>
              <div class="price-range">
                <span class="min"
                  ><span class="currency"></span> {{ filter.min }}</span
                >
                <span class="max"
                  ><span class="currency"></span> {{ filter.max }}</span
                >
              </div>
              <!--Range Slider-->
              <!--filter btn-->
              <button class="filter_btn" type="button">
                {{ $t("filter.title") }}
              </button>
              <!--filter btn-->
            </form>
          </div>
        </div>
      </div>
    </base-model>
    <!--Filter-->
  </div>
</template>

<script>
import ProductCard from "@/components/HomePage/Products/ProductCard.vue";
export default {
  data() {
    return {
      // Start:: Products
      products: [
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img1.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img2.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img3.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img4.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img5.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img6.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img7.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img8.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img9.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img10.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img1.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img2.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
      ],
      // END:: Products

      // Start:: Filter Data
      filter: {
        show: false,
        city: "",
        category: "",
        min: 1,
        max: 5000,
      },
      // End:: Filter Data
    };
  },
  methods: {
    //Show Model
    showModel() {
      this.filter.show = true;
    },
    //Show Model
    //Hide Model
    closeModel() {
      this.filter.show = false;
    },
    //Hide Model
  },
  components: {
    ProductCard,
  },
};
</script>

<style></style>
