<template>
  <div class="product_details" v-if="details">
    <!--Top Details-->
    <div class="head_details">
      <!--Right Part-->
      <div class="right_part">
        <!--Product Name-->
        <div class="product_name">
          <h4>{{ details.name }}</h4>
          <span>{{ details.created_at }}</span>
        </div>
        <!--Product Name-->
        <!--User Control-->
        <div class="user_control" v-if="details.provider.id == userId">
          <ul>
            <li>
              <router-link class="edit" :to="'/editAdvertisment/' + details.id"
                ><i class="fas fa-edit"></i
              ></router-link>
            </li>
            <li>
              <button class="delete" @click="ShowDeleteModel" type="button">
                <i class="fas fa-trash-alt"></i>
              </button>
            </li>
          </ul>
        </div>
        <!--User Control-->
      </div>
      <!--Right Part-->
      <!--Price-->
      <div class="price" v-if="details.category.id != 3">
        <h5>{{ details.price }}</h5>
        <span>{{ $t("currency") }}</span>
      </div>
      <!--Price-->
    </div>
    <!--Exp Details-->
    <div class="exp_details">
      <p>
        {{ details.desc }}
      </p>
      <ul class="tags" v-if="details.category">
        <li>
          <span>{{ details.category.name }}</span>
        </li>
        <li>
          <span>{{ details.watches }} {{ $t("singleProduct.view") }}</span>
        </li>
        <li>
          <span>{{ details.country.name }}, {{ details.city.name }}</span>
        </li>
        <li class="pro_location">
          <a @click="openMap"
            >{{ $t("singleProduct.location") }}
            <span class="icon"><i class="fas fa-angle-left"></i></span
          ></a>
        </li>
      </ul>
    </div>
    <!--Product Owner-->
    <div class="product_owner">
      <div class="who_upload">
        <h4>
          {{ $t("singleProduct.uploadedBy") }}
        </h4>
        <div class="owner_details" v-if="!details.is_free">
          <img :src="details.provider.image" />
          <h5>
            <router-link :to="'/provider/' + this.details.provider.id"
              >{{ details.provider.first_name }}
              {{ details.provider.last_name }}</router-link
            >
          </h5>
          <Rate :rate_num="Math.floor(details.provider.rates)"></Rate>
        </div>
        <div class="owner_details" v-else>
          <h5>{{ $t("advertisment.add.no") }}</h5>
        </div>
      </div>
      <div class="product_status">
        <h5>{{ $t("singleProduct.productStatus") }}</h5>
        <!-- <span v-if="details.status == 'for_sell'">{{
          $t("singleProduct.forSale")
        }}</span>
        <span v-if="details.status == 'paid'">{{
          $t("singleProduct.paid")
        }}</span> -->
        <span v-if="details.type == 'new'">{{ $t("singleProduct.new") }}</span>
        <span v-if="details.type == 'used'">{{
          $t("singleProduct.used")
        }}</span>
      </div>
    </div>
    <!--Contact Seller-->
    <div class="contact_seller" v-if="$store.getters['auth_module/token']">
      <div class="con_sel" v-if="details.provider.id != userId">
        <button @click="createCaht()" type="button" class="con_link">
          {{ $t("singleProduct.callSeller") }}
        </button>
      </div>
      <div class="add_favourite">
        <button type="button" @click="add_Favourite()" class="btn">
          <i v-if="!details.is_liked" class="far fa-heart"></i>
          <i v-if="details.is_liked" class="fas fa-heart"></i>
        </button>
      </div>
      <div class="share">
        <button @click="toggleSocial" class="btn" type="button">
          <i class="far fa-share-alt"></i>
        </button>
        <ul :class="{ show: socialShare }">
          <li class="facebook" @click="sharePoints">
            <ShareNetwork
              network="facebook"
              :url="pageLocation"
              :title="details.name"
              :description="details.desc"
              quote="The hot reload is so fast it\'s near instant. - Evan You"
              hashtags="manfaa"
            >
              <i class="fab fa-facebook-f"></i>
            </ShareNetwork>
          </li>
          <li class="twitter" @click="sharePoints">
            <ShareNetwork
              network="twitter"
              :url="pageLocation"
              :title="details.name"
              :description="details.desc"
              quote="The hot reload is so fast it\'s near instant. - Evan You"
              hashtags="manfaa"
            >
              <i class="fab fa-twitter"></i>
            </ShareNetwork>
          </li>
          <li class="linkedin" @change="sharePoints">
            <ShareNetwork
              network="LinkedIn"
              :url="pageLocation"
              :title="details.name"
              :description="details.desc"
              quote="The hot reload is so fast it\'s near instant. - Evan You"
              hashtags="manfaa"
            >
              <i class="fab fa-linkedin"></i>
            </ShareNetwork>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="change_status"
      v-if="
        $store.getters['auth_module/token'] &&
        details.provider.id == userId &&
        details.status == 'for_sell'
      "
    >
      <button type="button" class="main_button button" @click="changeStatus()">
        <i class="fas fa-spinner"></i> {{ details.category.name }}
      </button>
    </div>
    <!--Delete Product Model-->
    <base-model :show="showDelete" @closeModel="HideDeleteModel">
      <div class="delete_product">
        <h4>{{ $t("singleProduct.delete") }}</h4>
        <div class="bottom_btns">
          <button
            class="delete button"
            type="button"
            ref="delete"
            :class="{ click: btn_loding }"
            @click="deleteProduct"
          >
            <i class="fas fa-spinner"></i>
            {{ $t("delete") }}
          </button>
          <button class="cancel button" type="button" @click="HideDeleteModel">
            {{ $t("cancel") }}
          </button>
        </div>
      </div>
    </base-model>
    <!--Delete Product Model-->
  </div>
</template>

<script>
// import ProductDetails from "../Loaders/ProductDetails.vue";
export default {
  props: ["details"],
  data() {
    return {
      //START SHOW LIKE
      like: false,
      //END SHOW LIKE

      //START SHOW DELETE
      showDelete: false,
      //END SHOW DELETE

      //START BTN LOADING
      btn_loding: false,
      //END BTN LOADING

      //START PAGE LOCATION
      pageLocation: window.location.href,
      //END PAGE LOCATION

      //START SHOW SOCIAL
      socialShare: false,
      //END SHOW SOCIAL
    };
  },
  methods: {
    //Add To Favourite
    add_Favourite() {
      const data = new FormData();
      data.append("product_id", this.details.id);
      this.$axios.post("client/like/toggle", data).then(() => {
        this.details.is_liked = !this.details.is_liked;
      });
    },
    //Add To Favourite

    //Show Delete Model
    ShowDeleteModel() {
      this.showDelete = true;
    },
    //Show Delete Model

    //Hide Delete Model
    HideDeleteModel() {
      this.showDelete = false;
    },
    //Hide Delete Model

    //Start Delete Product
    deleteProduct() {
      const submitButton = this.$refs["delete"];
      submitButton.classList.add("click");
      this.$axios
        .delete("client/product/" + this.details.id)
        .then(() => {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.success({
              timeout: 2000,
              message: this.$t("deleteDone"),
              position: "bottomRight",
            });
            this.$router.push({ path: "/" });
          }, 1000);
        })
        .catch((err) => {
          setTimeout(() => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          }, 1000);
        });
    },
    //End Delete Product

    //Start Set Watch
    setWatch() {
      const data = new FormData();
      data.append("product_id", this.details.id);
      this.$axios.post("client/set/watch", data).then(() => {});
    },
    //End Set Watch

    //Start Show Social
    toggleSocial() {
      this.socialShare = !this.socialShare;
    },
    //End Show Social

    //Share Points
    sharePoints() {
      this.$axios({
        method: "post",
        url: "client/share",
      }).then(() => {});
    },
    //Share Points

    //Open Map
    openMap() {
      var url =
        "https://maps.google.com/?q=" +
        this.details.lat +
        "," +
        this.details.lng;
      window.open(url);
    },
    //Open Map

    //Create Chat
    createCaht() {
      const data = new FormData();
      data.append("provider_id", this.details.provider.id);
      data.append("product_id", this.details.id);
      data.append("type", "order");
      this.$axios.post("client/chat", data).then((res) => {
        // this.$router.push({ path: `/singleChat/ + ${res.data.data.id}` });
        this.$router.push({ path: "/singleChat/" + res.data.data.id });
      });
    },
    //Create Chat

    //Change  Status
    changeStatus() {
      const data = new FormData();
      data.append("product_id", this.details.id);
      this.$axios({
        method: "post",
        url: "client/set/paid",
        data: data,
      })
        .then(() => {
          this.$iziToast.success({
            timeout: 2000,
            message: this.$t("saveSuccess"),
            position: "bottomRight",
          });
          location.reload();
        })
        .catch((err) => {
          this.$iziToast.err({
            timeout: 2000,
            message: err.response.data.message,
            position: "bottomRight",
          });
        });
    },
    //Change  Status
  },
  computed: {
    userId() {
      return this.$store.getters["auth_module/set_userId"];
    },
  },
  created() {
    setTimeout(() => {
      this.setWatch();
    }, 1000);
  },
  components: {
    // ProductDetails,
  },
};
</script>

<style></style>
