<template>
  <div class="my_orders pad_70 all_bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="main_title with_margin">
            <h3>{{ $t("profile.myOrders.title") }}</h3>
          </div>
        </div>

        <data-loader v-if="loading"></data-loader>
        <div class="col-md-12" v-else>
          <!--User Info-->
          <myTabs :tabsContent="tabsContent">
            <template v-slot:current>
              <no-data v-if="currentOrders.length == 0"></no-data>
              <div class="row" v-else>
                <div
                  class="col-md-6"
                  v-for="order in currentOrders"
                  :key="order.id"
                >
                  <OrderCard :order="order"></OrderCard>
                </div>
              </div>
            </template>

            <template v-slot:previous>
              <no-data v-if="finishedOrders.length == 0"></no-data>
              <div class="row" v-else>
                <div
                  class="col-md-6"
                  v-for="order in finishedOrders"
                  :key="order.id"
                >
                  <OrderCard :order="order"></OrderCard>
                </div>
              </div>
            </template>
          </myTabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderCard from "@/components/Orders/OrderCard.vue";

export default {
  data() {
    return {
      // START:: ORDERS
      currentOrders: [],
      finishedOrders: [],
      // END:: ORDERS

      // START:: TABS DATA
      tabsContent: [
        {
          tab: this.$t("profile.myOrders.current"),
          key_name: "current",
        },
        {
          tab: this.$t("profile.myOrders.previous"),
          key_name: "previous",
        },
      ],
      // END:: TABS DATA

      // START:: LOADING
      loading: false,
      // END:: LOADING
    };
  },

  methods: {
    GetOrders() {
      this.loading = true;
      this.$axios.get(`client/order`).then((res) => {
        this.currentOrders = res.data.data.current;
        this.finishedOrders = res.data.data.finished;
        this.loading = false;
      });
    },
  },

  created() {
    this.GetOrders();
  },

  components: {
    OrderCard,
  },
};
</script>
