<template>
  <div class="product_card" v-if="productItem">
    <div class="product_img">
      <img
        @error="setDefultImage"
        :src="productItem.image ? productItem.image.link : ''"
      />
    </div>
    <div class="product_exp">
      <router-link :to="'/singleProduct/' + productItem.id"
        ><div class="inner_over"></div
      ></router-link>
      <div class="product_name">
        <router-link :to="'/singleProduct/' + productItem.id">{{
          productItem.name
        }}</router-link>
      </div>
      <div class="product_details">
        <div class="address">
          <i class="fal fa-map-marker-alt"></i>
          <span>{{ productItem.city.name.substring(0, 25) }}</span>
        </div>
        <div class="price" v-if="productItem.category.id != 3">
          <span class="num">{{ productItem.price }}</span>
          <span class="text">{{ $t("products.productCard.price") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productItem: {
      required: true,
    },
  },
  methods: {
    //Replace Image
    setDefultImage(e) {
      e.target.src = require("@/assets/images/Icons/hidden-image.png");
    },
    //Replace Image
  },
  computed: {
    // imageLink() {
    //   return productItem.image?.link;
    // },
  },
};
</script>

<style></style>
