<template>
  <div class="login most_bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <!--Start Auth Page-->
          <div class="auth_form">
            <!--Start Top Part-->
            <div class="auth_top">
              <div class="logo">
                <router-link to="/"
                  ><img src="@/assets/images/Icons/logo.png"
                /></router-link>
              </div>
              <div class="title_content">
                <h4>{{ $t("activate.title") }}</h4>
                <p>{{ $t("activate.desc") }}</p>
              </div>
              <div class="edit_phone">
                <p>{{ userPhone }}</p>
                <!-- <router-link to="">تعديل رقم الجوال</router-link> -->
              </div>
            </div>
            <!--End Top Part-->
            <!--Start Inputs Part-->
            <div class="auth_inputs">
              <form>
                <div dir="ltr" class="wrapper_flex code_wrapper input_wrapper">
                  <input
                    ref="firstInput"
                    class="form-control"
                    type="text"
                    maxlength="1"
                    size="1"
                    @input="toggleFocus"
                    v-model="data.num1"
                  />
                  <input
                    class="form-control"
                    type="text"
                    maxlength="1"
                    size="1"
                    @input="toggleFocus"
                    v-model="data.num2"
                  />
                  <input
                    class="form-control"
                    type="text"
                    maxlength="1"
                    size="1"
                    @input="toggleFocus"
                    v-model="data.num3"
                  />
                  <input
                    class="form-control"
                    type="text"
                    maxlength="1"
                    size="1"
                    @input="toggleFocus"
                    v-model="data.num4"
                  />
                </div>
                <!--Dont Recevie Code-->
                <!-- <div class="receive_code">
                  <p>لم تستلم الكود ؟ يمكنك إعادة إرسال الكود بعد</p>
                  <p>03.00</p>
                  <span>إعادة الإرسال</span>
                </div> -->
                <!--Dont Recevie Code-->
                <!--Login Button-->
                <button
                  @click="activate"
                  type="button"
                  class="main_button button"
                  ref="activate"
                >
                  <i class="fas fa-spinner"></i>
                  {{ $t("auth.confirm") }}
                </button>
                <!--Login Button-->
              </form>
            </div>
            <!--End Inputs Part-->
            <!--Start Have Account-->
            <div class="have_account">
              <span class="red_text">{{ $t("signup.haveAccount") }}</span>
              <router-link to="/login" class="second_button">{{
                $t("signup.login")
              }}</router-link>
            </div>
            <!--End Have Account-->
          </div>
          <!--End Auth Page-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //START INPUTS DATA
      data: {
        num1: null,
        num2: null,
        num3: null,
        num4: null,
        allCode: null,
      },
      //END INPUTS DATA
    };
  },
  methods: {
    // Start Login
    activate() {
      const submitButton = this.$refs["activate"];
      submitButton.classList.add("click");
      if (this.data.num1 == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.code1"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.num2 == null) {
        submitButton.classList.remove("click");
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("auth.validation.code2"),
          position: "bottomRight",
        });
      } else if (this.data.num3 == null) {
        submitButton.classList.remove("click");
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("auth.validation.code3"),
          position: "bottomRight",
        });
      } else if (this.data.num4 == null) {
        submitButton.classList.remove("click");
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("auth.validation.code4"),
          position: "bottomRight",
        });
      } else {
        this.data.allCode =
          this.data.num1 + this.data.num2 + this.data.num3 + this.data.num4;
        const data = new FormData();
        data.append("phone", this.userPhone);
        data.append("code", this.data.allCode);
        data.append("type", "android");
        data.append("device_token", "dasd12312312sczxcvczx");
        this.$axios
          .post("/verify", data)
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$router.push({ path: "/login" });
              this.$iziToast.success({
                timeout: 2000,
                message: this.$("activateSuccess"),
                position: "bottomRight",
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
    //End Login

    toggleFocus(e) {
      if (e.currentTarget.value.length == 1) {
        if (e.currentTarget.nextSibling) {
          e.currentTarget.blur();
          e.currentTarget.nextSibling.focus();
        }
      } else if (e.currentTarget.value.length == 0) {
        if (e.currentTarget.previousSibling) {
          e.currentTarget.blur();
          e.currentTarget.previousSibling.focus();
        }
      }
    },
  },
  computed: {
    devMessage() {
      return this.$store.getters["auth_module/devMessage"];
    },
    userPhone() {
      return this.$store.getters["auth_module/userPhone"];
    },
  },
};
</script>

<style lang="scss" scoped></style>
