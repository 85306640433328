<template>
  <div class="last_offers all_bg pad_50">
    <div class="container">
      <product-loader v-if="loading"></product-loader>
      <div class="row" v-else>
        <no-data v-if="products.length == 0"></no-data>
        <template v-else>
          <div class="col-md-3" v-for="product in products" :key="product.id">
            <ProductCard :productItem="product"></ProductCard>
          </div>
          <!-- ========== Pagination ========== -->
          <div class="pagenation" v-if="last_page > 1">
            <ul>
              <li
                v-for="index in last_page"
                :class="index === currentPage ? 'active' : ''"
                :key="index"
                @click="indexNumber(index)"
              >
                {{ index }}
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/HomePage/Products/ProductCard.vue";
export default {
  props: ["id", "subId"],
  data() {
    return {
      // Start:: Products
      products: [
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img1.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img2.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img3.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img4.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img5.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img6.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img7.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img8.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img9.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img10.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img1.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
        {
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(0, 5),
          img: require("@/assets/images/products/img2.png"),
          name: "اسم المنتج",
          price: "50",
          address: "مكة المكرمة - الزاهر",
        },
      ],
      // END:: Products

      // Start:: Filter Data
      filter: {
        show: false,
        city: "",
        category: "",
        min: 1,
        max: 5000,
      },
      // End:: Filter Data

      //Start Loading
      loading: false,
      //End Loading

      // START :: PAGENATION
      last_page: "",
      currentPage: 1,
      pageNum: "",
      // END :: PAGENATION
    };
  },
  methods: {
    //Show Model
    showModel() {
      this.filter.show = true;
    },
    //Show Model
    //Hide Model
    closeModel() {
      this.filter.show = false;
    },
    //Hide Model

    // pagenation
    indexNumber(pageNumber) {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
      if (this.currentPage !== pageNumber) {
        this.currentPage = pageNumber;
        this.getProducts();
      }
    },
    // pagenation

    //Get Products
    getProducts() {
      this.loading = true;
      this.$axios
        .get(
          "products?category_id=" +
            this.id +
            "&subcategory_id=" +
            this.subId +
            "&page=" +
            this.currentPage
        )
        .then((res) => {
          this.products = res.data.data;
          this.loading = false;
          this.last_page = res.data.meta.last_page;
          this.pageNum = res.data.meta.to;
        });
    },
    //Get Products
  },
  components: {
    ProductCard,
  },
  mounted() {
    this.getProducts();
  },
};
</script>

<style></style>
