<template>
  <div class="favourite all_bg pad_50">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="main_title with_margin">
            <h3>{{ $t("favourite.title") }}</h3>
          </div>
        </div>
      </div>
      <product-loader v-if="loading"></product-loader>
      <div class="row" v-else>
        <no-data v-if="products.length == 0"></no-data>
        <template v-else>
          <div class="col-md-3" v-for="product in products" :key="product.id">
            <ProductCard :productItem="product"></ProductCard>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "@/components/HomePage/Products/ProductCard.vue";
export default {
  data() {
    return {
      // Start:: Products
      products: [],
      // END:: Products

      // Start:: Loading
      loading: false,
      // End:: Loading
    };
  },
  components: {
    ProductCard,
  },
  methods: {
    //Get Favourites
    getFavourites() {
      this.loading = true;
      this.$axios.get("client/favourites").then((res) => {
        this.products = res.data.data;
        this.loading = false;
      });
    },
    //Get Favourites
  },
  mounted() {
    this.getFavourites();
  },
};
</script>

<style></style>
