<template>
  <div class="chat_custom_card">
    <div class="chatCom">
      <!-- Start Header -->
      <header class="header">
        <div class="container">
          <div class="chat_user">
            <img :src="receiver.img" />
            <div class="text">
              <h3 class="mb-1">{{ receiver.name }}</h3>
              <Rate :rate_num="receiver.rate"></Rate>
            </div>
          </div>

          <div class="report_icon">
            <button class="btn" type="button" @click="ShowReportModel">
              <img class="icon" src="../../assets/images/Icons/report.png" />
            </button>
          </div>
        </div>
      </header>
      <!-- End header -->

      <!-- Start ChatBox -->
      <div class="chat_box">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-4">
              <div class="elan_content">
                <img :src="chatObj.product.image.link" />
                <h4>{{ chatObj.product.name }}</h4>
                <router-link
                  :to="'/orderProvider/' + chatObj.product.id"
                  v-if="!allData.check_product_has_order"
                  class="mainButton"
                  >{{ $t("askDriver") }}</router-link
                >
              </div>
            </div>
          </div>

          <div class="chat_view" v-chat-scroll="{ smooth: true }">
            <div
              v-for="message in messages"
              :key="message.id"
              class="messageOwner"
              :class="{
                sender: message.owner == 'me',
                receiver: message.owner == 'other',
              }"
            >
              <img
                @error="setDefultImage"
                class="user_img"
                :src="message.user.image"
              />
              <div class="content">
                <div
                  class="media"
                  v-if="
                    message.type == 'image' ||
                    message.type == 'video' ||
                    message.type == 'audio'
                  "
                >
                  <div v-if="message.type == 'image'" class="image">
                    <img :src="message.message" />
                    <p v-if="message.descreption" class="descreption">
                      {{ message.descreption }}
                    </p>
                  </div>

                  <div v-if="message.type == 'video'" class="video">
                    <video controls>
                      <source :src="message.message" type="video/mp4" />
                    </video>
                    <p v-if="message.descreption" class="descreption">
                      {{ message.descreption }}
                    </p>
                  </div>

                  <div v-if="message.audio_src" class="audio_media">
                    <audio controls>
                      <source :src="message.audio_src" type="audio/ogg" />
                    </audio>
                  </div>
                </div>

                <p
                  v-if="message.type == 'text'"
                  v-html="message.message.replace(/(\n|\n|\r)/gm, '<br />')"
                  class="text"
                ></p>
              </div>
            </div>
          </div>

          <!-- Chat Footer -->
          <footer class="chat_footer" v-if="!readOnly">
            <!-- ====== Send_Input ===== -->
            <div class="chat_input_wrapper">
              <textarea
                ref="message_text"
                v-model="data.message_text"
                :placeholder="$t('writeMes')"
                @keydown.enter="send_message"
                @keyup="textAreaAdjust"
              />

              <!-- <div class="icon">
              <div class="attachments">
                <span @click.stop="toggleUplodes"
                  ><i class="far fa-paperclip"></i
                ></span>

                <transition name="fadeInUp">
                  <ul v-if="uplodesActive" class="uplode_options">
                    <li>
                      <input
                        class="uplode_iamge uplode_Input"
                        type="file"
                        id="uplode_iamge"
                        name="uplode_image"
                        accept="image/*"
                        @change="upload_image"
                      />
                      <label for="uplode_iamge"
                        ><i class="far fa-image-polaroid"></i
                      ></label>
                    </li>
                    <li>
                      <input
                        class="uplode_video uplode_Input"
                        type="file"
                        id="uplode_video"
                        name="uplode_video"
                        accept="video/*"
                        @change="upload_video"
                      />
                      <label for="uplode_video">
                        <i class="far fa-video-slash"></i>
                      </label>
                    </li>
                  </ul>
                </transition>
              </div>
            </div> -->
            </div>

            <!-- ====== Send_button ===== -->
            <button @click="send_message" class="send_button">
              <span><i class="fas fa-paper-plane"></i></span>
            </button>

            <!-- <button v-else class="record send_button">
            <audio-recorder :after-recording="after_recording" />
          </button> -->
          </footer>
        </div>

        <!-- Image Uplode -->
        <transition name="fadeInUp">
          <div v-if="showPreview_image" class="uplode_preview">
            <span @click="close_preview" class="close"
              ><i class="far fa-times"></i
            ></span>
            <img
              class="preview_img preview_media"
              :src="data.file_image_src"
              alt="..."
            />

            <footer class="footer">
              <div class="send_button">
                <!-- <textarea
                  ref="message_text"
                  v-model="data.descreption"
                  type="text"
                  placeholder="اضف نص توضيحي"
                  @keydown.enter="send_message"
                ></textarea> -->

                <v-btn
                  :loading="btn_loaidng"
                  class="active center send_message"
                  @click="send_message"
                  elevation="5"
                >
                  <i class="fas fa-paper-plane"></i>
                </v-btn>
              </div>
            </footer>
          </div>
        </transition>

        <!-- Video Uplode -->
        <transition name="fadeInUp">
          <div v-if="showPreview_video" class="uplode_preview">
            <span @click="close_preview" class="close"
              ><i class="far fa-times"></i
            ></span>

            <video class="preview_video preview_media" controls>
              <source :src="data.file_video_src" type="video/mp4" />
            </video>

            <footer class="footer">
              <div class="send_button">
                <!-- <textarea
                  ref="message_text"
                  v-model="data.descreption"
                  type="text"
                  placeholder="اضف نص توضيحي"
                  @keydown.enter="send_message"
                ></textarea> -->

                <v-btn
                  :loading="btn_loaidng"
                  class="active center send_message"
                  @click="send_message"
                  elevation="5"
                >
                  <i class="fas fa-paper-plane"></i>
                </v-btn>
              </div>
            </footer>
          </div>
        </transition>
      </div>
      <!-- End ChatBox -->
    </div>
    <!--Report Reason-->
    <base-model :show="show" @closeModel="HideReportModel">
      <div class="report">
        <h4>{{ $t("report.title") }}</h4>
        <p>{{ $t("report.desc") }}</p>
        <ul>
          <li v-for="res in reasons" :key="res.id">
            <input
              type="radio"
              :value="res.id"
              v-model="reason"
              :id="'radio' + res.id"
              name="r"
            />
            <label :for="'radio' + res.id">{{ res.name }}</label>
          </li>
        </ul>
        <button
          ref="report"
          class="main_button button"
          type="button"
          @click="reportUser"
        >
          <i class="fas fa-spinner"></i>
          {{ $t("sendBtn") }}
        </button>
      </div>
    </base-model>
    <!--Report Reason-->
  </div>
</template>

<script>
export default {
  // props: ["chatObj", "readOnly"],

  props: {
    chatObj: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      // Loading
      btn_loaidng: false,

      receiver: null,

      //Report Reason
      show: false,
      reasons: [],
      reason: "",

      //allData
      allData: null,

      // Chat
      showPreview_video: false,
      showPreview_image: false,

      uplodesActive: false,

      messages: [],

      data: {
        message_text: "",
        // image
        file_image: "",
        file_image_src: "",
        // video
        file_video: "",
        file_video_src: "",
        // audio
        file_audio_src: "",

        descreption: "",
      },
    };
  },

  computed: {
    canRecord() {
      return this.data.message_text == "";
    },
  },

  methods: {
    // Send Message
    send_message(e) {
      // To go To another line use [Ctrl + Enter]
      if (e) {
        if (
          (e.ctrlKey && e.key === "Enter") ||
          (e.shiftKey && e.key === "Enter")
        ) {
          this.data.message_text += "\n";
          return;
        } else {
          e.preventDefault();
        }
      }

      // Create message Object
      const message = {
        type: "sender",
        text: this.data.message_text,
        image_src: this.data.file_image_src,
        video_src: this.data.file_video_src,
        audio_src: this.data.file_audio_src,
        descreption: this.data.descreption,
      };

      // ==========> Validation
      if (
        message.text == "" &&
        message.image_src == "" &&
        message.video_src == "" &&
        message.audio_src == ""
      ) {
        return;
      }

      // Message Type
      let messageType = "";
      if (this.data.file_image_src) {
        messageType = "image";
      } else if (this.data.file_video_src) {
        messageType = "video";
      } else if (this.data.file_audio_src) {
        messageType = "audio";
      } else if (this.data.message_text) {
        messageType = "text";
      } else {
        messageType = "file";
      }

      // Message
      let message_content = null;
      if (this.data.file_image) {
        message_content = this.data.file_image_src; // just for now
        // message_content = this.data.file_image;
      } else if (this.data.file_audio_src) {
        message_content = this.data.file_audio_src;
      } else if (this.data.file_video) {
        message_content = this.data.file_video_src; // just for now
        // message_content = this.data.file_video;
      } else if (this.data.message_text) {
        message_content = this.data.message_text;
      }

      // ***** Send Message
      if (e.target.tagName == "TEXTAREA") {
        e.target.style.height = 49 + "px";
      }

      this.btn_loaidng = true;
      let my_data = new FormData();
      my_data.append(`message_type`, messageType);
      my_data.append(`content`, message_content);
      my_data.append(`id`, this.chatObj.id);

      this.$axios({
        method: "post",
        url: `client/chat/message`,
        data: my_data,
      })
        .then(() => {
          this.messages.push({
            owner: "me",
            type: messageType,
            message: message_content,
            descreption: message.descreption,
            audio_src: message.audio_src,
            user: {
              image:
                "https://thumbs.dreamstime.com/z/easiest-way-to-communicate-mobile-phone-users-group-friends-talking-cell-phones-modern-people-smartphones-176743416.jpg",
            },
          });

          this.btn_loaidng = false;

          // Play Music
          new Audio(require("@/assets/sounds/message_sent.mp3")).play();

          // Clear Data
          this.data.message_text = "";
          this.data.descreption = "";
          this.data.file_image_src = "";
          this.data.file_image = "";
          this.data.file_video_src = "";
          this.data.file_video = "";
          this.data.file_audio_src = "";
          this.showPreview_image = false;
          this.showPreview_video = false;

          // Focus
          this.$refs.message_text.focus();
        })
        .catch((err) => {
          console.log(err);
          this.btn_loaidng = false;
          this.showPreview_image = false;
          this.showPreview_video = false;
          this.$iziToast.error({
            title: "خطأ",
            message: err.response.data.message,
          });
        });
    },

    // Adjust Height on input
    textAreaAdjust(e) {
      if (
        (e.ctrlKey && e.key === "Enter") ||
        (e.shiftKey && e.key === "Enter")
      ) {
        e.target.style.height = e.target.scrollHeight + "px";
      }
    },

    // Uplods
    upload_image(e) {
      this.data.file_image = e.target.files[0];
      this.data.file_image_src = URL.createObjectURL(this.data.file_image);

      this.showPreview_image = true;
      e.target.value = null;
    },
    upload_video(e) {
      this.data.file_video = e.target.files[0];
      this.data.file_video_src = URL.createObjectURL(this.data.file_video);

      this.showPreview_video = true;
      e.target.value = null;
    },
    toggleUplodes(close) {
      if (close == false) {
        this.uplodesActive = false;
      } else {
        this.uplodesActive = !this.uplodesActive;
      }
    },
    close_preview() {
      this.showPreview_image = false;
      this.showPreview_video = false;
    },

    // Record
    after_recording(data) {
      this.data.file_audio_src = data.url;
      this.send_message();
    },

    //Show Report
    ShowReportModel() {
      this.show = true;
    },
    //Show Report
    //Hide Report
    HideReportModel() {
      this.show = false;
    },
    //Hide Report

    //Replace Image
    setDefultImage(e) {
      e.target.src = require("@/assets/images/Icons/hidden-image.png");
    },
    //Replace Image

    //Get Report Reason
    getReasons() {
      this.$axios.get("reasons").then((res) => {
        this.reasons = res.data.data;
      });
    },
    //Get Report Reason

    //Report User
    reportUser() {
      const submitButton = this.$refs["report"];
      submitButton.classList.add("click");
      if (this.reason == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("report.desc"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        const data = new FormData();
        data.append("provider_id", this.allData.provider.id);
        data.append("reason_id", this.reason);
        this.$axios
          .post("report", data)
          .then((res) => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.HideReportModel();
              this.$iziToast.success({
                timeout: 2000,
                message: res.data.message,
                position: "bottomRight",
              });
            }, 1000);
          })
          .catch((err) => {
            setTimeout(() => {
              submitButton.classList.add("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
    //Report User
  },

  created() {
    // Set Data
    this.messages = this.chatObj.messages;

    if (this.chatObj.owner == "provider") {
      this.receiver = {
        name: this.chatObj.user.first_name + this.chatObj.user.last_name,
        rate: this.chatObj.user.rates,
        img: this.chatObj.user.image,
      };
    } else if (this.chatObj.owner == "user") {
      this.receiver = {
        name:
          this.chatObj.provider.first_name + this.chatObj.provider.last_name,
        rate: this.chatObj.provider.rates,
        img: this.chatObj.provider.image,
      };
    }

    this.allData = this.chatObj;

    this.getReasons();

    // Close Uplods
    window.addEventListener("click", () => {
      this.uplodesActive = false;
    });
  },
};
</script>
