export default {
    set_filter(state, payload) {
        state.filter = {
            city: payload.city,
            category: payload.category,
            priceFrom: payload.priceFrom,
            priceTo: payload.priceTo,
        };
        console.log("mutation: ", state.filter);
    },

};