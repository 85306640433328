<template>
  <div class="rating_form">
    <div class="row justify-content-center">
      <div class="col-md-9">
        <form>
          <div class="rating">
            <input
              name="stars"
              id="d5"
              value="5"
              type="radio"
              v-model="rate"
            /><label for="d5"><i class="fas fa-star"></i></label>
            <input
              name="stars"
              value="4"
              id="d4"
              type="radio"
              v-model="rate"
            /><label for="d4"><i class="fas fa-star"></i></label>
            <input
              name="stars"
              value="3"
              id="d3"
              type="radio"
              v-model="rate"
            /><label for="d3"><i class="fas fa-star"></i></label>
            <input
              name="stars"
              value="2"
              id="d2"
              type="radio"
              v-model="rate"
            /><label for="d2"><i class="fas fa-star"></i></label>
            <input
              name="stars"
              value="1"
              id="d1"
              type="radio"
              v-model="rate"
            /><label for="d1"><i class="fas fa-star"></i></label>
          </div>
          <div class="input_wrapper round_radius">
            <input
              type="text"
              v-model="comment"
              class="form-control"
              :placeholder="$t('rate.addComment')"
            />
          </div>
          <div class="rate_btn">
            <button
              @click="addRate"
              type="button"
              class="button btn"
              ref="rating"
            >
              <i class="fas fa-spinner"></i>
              {{ $t("sendBtn") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["providerId"],
  data() {
    return {
      //START RATE
      rate: null,
      //END RATE

      //START COMMENT
      comment: "",
      //END COMMENT
    };
  },
  methods: {
    //Start Add Rating
    addRate() {
      const submitButton = this.$refs["rating"];
      submitButton.classList.add("click");
      if (this.rate == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("rate.validation.rate"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.comment == "") {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("rate.validation.comment"),
            position: "bottomRight",
          });
        }, 1000);
      } else {
        const data = new FormData();
        data.append("provider_id", this.providerId);
        data.append("rate", this.rate);
        data.append("comment", this.comment);
        this.$axios
          .post("client/review-provider", data)
          .then(() => {
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("rate.validation.rateDone"),
                position: "bottomRight",
              });
            }, 1000);
          })
          .catch((err) => {
            submitButton.classList.remove("click");
            this.$iziToast.error({
              timeout: 2000,
              message: err.response.data.message,
              position: "bottomRight",
            });
          });
      }
    },
    //End Add Rating
  },
};
</script>

<style></style>
