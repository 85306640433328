<template>
  <div class="login most_bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <!--Start Auth Page-->
          <div class="auth_form">
            <!--Start Top Part-->
            <div class="auth_top">
              <div class="logo">
                <router-link to="/"
                  ><img src="@/assets/images/Icons/logo.png"
                /></router-link>
              </div>
              <div class="title_content">
                <h4>{{ $t("login.title") }}</h4>
                <p>{{ $t("login.desc") }}</p>
              </div>
            </div>
            <!--End Top Part-->
            <!--Start Inputs Part-->
            <div class="auth_inputs">
              <form @submit.prevent="login">
                <div class="wrapper_flex">
                  <!--Start Phone Code-->
                  <!-- <div class="input_wrapper phone_code select">
                    <select class="form-control" v-model="data.phoneCode">
                      <option value="+698">+698</option>
                      <option value="+698">+698</option>
                      <option value="+698">+698</option>
                    </select>
                  </div> -->
                  <!--End Phone Code-->
                  <!--Start Phone Number-->
                  <div class="input_wrapper phone_num">
                    <input
                      class="form-control"
                      type="number"
                      v-model="data.phone"
                      :placeholder="$t('auth.phone')"
                    />
                  </div>
                  <!--Start Phone Number-->
                </div>
                <!--Start Password-->
                <div class="input_wrapper">
                  <input
                    class="form-control"
                    type="password"
                    v-model="data.password"
                    :placeholder="$t('auth.password')"
                  />
                </div>
                <!--End Password-->
                <!--Forget Password-->
                <router-link class="forget_pass" to="/forgetPassword">{{
                  $t("auth.forgetpassword")
                }}</router-link>
                <!--Forget Password-->
                <!--Login Button-->
                <button class="main_button button" ref="login">
                  <i class="fas fa-spinner"></i>
                  {{ $t("auth.login") }}
                </button>
                <!--Login Button-->
              </form>
            </div>
            <!--End Inputs Part-->
            <!--Start Have Account-->
            <div class="have_account">
              <span class="red_text">{{ $t("login.haveAccount") }}</span>
              <router-link to="/signUp" class="second_button">{{
                $t("login.createAccount")
              }}</router-link>
            </div>
            <!--End Have Account-->
          </div>
          <!--End Auth Page-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //START INPUTS DATA
      data: {
        phoneCode: null,
        phone: null,
        password: null,
      },
      loginData: {
        token: "",
        userType: "",
        userId: null,
        image: null,
        phone: null,
      },
      //END INPUTS DATA
      // Start:: ACTIVATE DATA
      activateData: {
        phone: null,
        code: null,
      },
      // END:: ACTIVATE DATA
    };
  },
  methods: {
    // Start Login
    login() {
      const submitButton = this.$refs["login"];
      submitButton.classList.add("click");
      // if (this.data.phoneCode == null) {
      //   setTimeout(() => {
      //     submitButton.classList.remove("click");
      //     this.$iziToast.error({
      //       timeout: 2000,
      //       message: this.$t("auth.validation.phoneCode"),
      //       position: "bottomRight",
      //     });
      //   }, 1000);
      // }
      if (this.data.phone == null) {
        setTimeout(() => {
          submitButton.classList.remove("click");
          this.$iziToast.error({
            timeout: 2000,
            message: this.$t("auth.validation.phone"),
            position: "bottomRight",
          });
        }, 1000);
      } else if (this.data.password == null) {
        submitButton.classList.remove("click");
        this.$iziToast.error({
          timeout: 2000,
          message: this.$t("auth.validation.password"),
          position: "bottomRight",
        });
      } else {
        const data = new FormData();
        data.append("username", this.data.phone);
        data.append("password", this.data.password);
        data.append("type", "android");
        data.append("device_token", "dasij21oh3o123l");
        this.$axios
          .post("/login", data, {
            headers: {
              "cache-control": "no-cache",
              "Content-type": `multipart/form-data;`,
              Accept: "application/json",
            },
          })
          .then((res) => {
            this.loginData.token = res.data.data.token;
            this.loginData.userType = res.data.data.type;
            this.loginData.userId = res.data.data.id;
            this.loginData.image = res.data.data.image;
            this.loginData.phone = res.data.data.phone;
            this.$store.dispatch("auth_module/logIn", this.loginData);
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$router.push({ path: "/" });
              this.$iziToast.success({
                timeout: 2000,
                message: this.$t("loginSuccess"),
                position: "bottomRight",
              });
            }, 1000);
          })
          .catch((err) => {
            // if (err.response.data.status == "fail") {
            //   this.activateData.code = err.response.data.dev_message;
            //   this.activateData.phone = this.data.phone;
            //   this.$store.dispatch("auth_module/register", this.activateData);
            //   setTimeout(() => {
            //     this.$router.push({ path: "/activateAccount" });
            //     submitButton.classList.remove("click");
            //     this.$iziToast.error({
            //       timeout: 2000,
            //       message: err.response.data.message,
            //       position: "bottomRight",
            //     });
            //   }, 1000);
            // } else {
            //   setTimeout(() => {
            //     submitButton.classList.remove("click");
            //     this.$iziToast.error({
            //       timeout: 2000,
            //       message: err.response.data.message,
            //       position: "bottomRight",
            //     });
            //   }, 1000);
            // }
            setTimeout(() => {
              submitButton.classList.remove("click");
              this.$iziToast.error({
                timeout: 2000,
                message: err.response.data.message,
                position: "bottomRight",
              });
            }, 1000);
          });
      }
    },
    //End Login
  },
};
</script>
